import { Button, Form, Icon, Input, message, Select } from 'antd'
import React, { useState, useEffect, useRef } from 'react'
import ClientWebService from '../../../classes/ClientWebService';
import MessengerWebService from '../../../classes/MessengerWebService';
import JoditEditor from "jodit-react";

const { Option } = Select;
const webservice = new ClientWebService();
const msgWebservice = new MessengerWebService();
const config = {
    readonly: false // all options from https://xdsoft.net/jodit/doc/
}
function MessengerIndexPage(props) {

    const { getFieldDecorator } = props.form;
    // const [editorState, setEditorState] = useState(()=> EditorState.createEmpty(),)
    const [editorState, setEditorState] = useState("")
    const [state, setstate] = useState([])
    const [isLoading, setLoading] = useState(false)
    const editor = useRef();

 
    const fetch = async () => {
        try {
            webservice.getAllUsers()
                .then(res => res.data.entries)
                .then(items => setstate(items));
        } catch (error) {
            message.error("Failed to fetch users");
        }
    }


    const onSubmit = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                setLoading(true);
               
                await msgWebservice
                    .send(values)
                    .then(resp => {
                        message.success("Message sent!");
                    })
                    .catch(err => {
                        message.error(`Could not send. ${err.message} `);
                    });

                setLoading(false);

            }
        })
    }

    useEffect(() => {
        fetch();
    }, [])
    return (
        <div>
            Message index

            <div className="container">
                <div>

                    <Form layout="vertical" >
                        <div className="row">
                            <div className="col col-md-12">
                                <Form.Item label="Subject">
                                    {getFieldDecorator('subject', {
                                        rules: [{ required: true, message: 'Please enter  code' }],
                                    })(<Input placeholder="" />)}
                                </Form.Item>
                            </div>


                            <div className="col col-md-12">
                                <Form.Item label="Message">
                                    {getFieldDecorator('message', {
                                        rules: [{ required: true, message: 'Please enter message' }],
                                        value: editorState
                                    })(
                                        <JoditEditor
                                            ref={editor}
                                           
                                            config={config}
                                            tabIndex={1} // tabIndex of textarea
                                            // onBlur={newContent =>console.log(newContent)} // preferred to use only this option to update the content for performance reasons
                                            onChange={newContent =>setEditorState(newContent)}
                                        />
                                    )}
                                </Form.Item>
                            </div>




                            <div className="col col-md-12">
                                <Form.Item label="recipients">
                                    {getFieldDecorator('recipients', {
                                        rules: [{ required: true, message: "Indicate recipients" }],
                                    })(
                                        <Select showSearch mode="multiple">
                                            <Option value="all"> All Users</Option>
                                            {state.map((usr) => <Option key={usr._id} value={usr.email}>{usr.name}</Option>)}
                                        </Select>,
                                    )}
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                    <div
                        style={{
                            position: 'absolute',
                            right: 0,
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e9e9e9',
                            padding: '10px 16px',
                            background: '#fff',
                            textAlign: 'right',
                        }}
                    >

                        <Button disabled={isLoading} type="button" onClick={onSubmit} type="primary">
                            {isLoading ? <Icon type="loading" /> : "Submit"}
                        </Button>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default Form.create()(MessengerIndexPage);