import React,{useState,useContext} from 'react'
import { Layout, Menu, Icon } from 'antd';
import env from "../../environments";
import { buildMenuItems } from "../../utils";
import { adminRoutes } from "../../routers/routes";
import GlobalStateContext from '../../context';
const {  Sider } = Layout;

const { SubMenu } = Menu;

export default function Sidebar() {

    const [gState,dispatch] = useContext(GlobalStateContext)
    const {configuration} = gState;
    const [state,setState] = useState({
        collapsed: false,
      });
    
      const onCollapse = collapsed => {
        setState({ collapsed });
      };

      

    
    return (
        <Sider collapsible collapsed={state.collapsed} onCollapse={onCollapse}>
        <div className="logo" > {configuration.app_name} </div>
        <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
          {buildMenuItems(adminRoutes)}
        </Menu>
      </Sider>
    )
}
