import React, { useContext, useState } from 'react'
import { Layout, Breadcrumb, Menu, Dropdown, Icon, Col, Row, Button, Drawer, Divider, Empty } from 'antd';
import Sidebar from '../components/admin/Sidebar';
import UserProfile from '../components/admin/UserProfile';
import env from "../environments";
import MenuItem from 'antd/lib/menu/MenuItem';
import GlobalStateContext from '../context';
import { LOGOUT } from '../store/actions/types';
import { withRouter, useHistory, Link } from 'react-router-dom';
import { adminRoutes } from '../routers/routes';

const { Header, Footer, Content } = Layout;

function renderBreadcrumb(route, params, routes, paths) {
  const isin = paths.indexOf(route.path) != -1;

  return isin ? (
    <Link to={paths.join('/')}>{route.breadcrumbName}</Link>

  ) : null;
}

const MaintenanceBar = () => {
  return (
    <Row>
      <div className='maintenance'>
        Maintenance Mode
      </div>

    </Row>
  )
}


function AdminLayout({ children }) {
  const [state, dispatch] = useContext(GlobalStateContext);
  const { configuration } = state;
  const [showUserProfile, setUserProfileVisibility] = useState(false);
  const history = useHistory();
  const logOut = () => {
    dispatch({
      type: LOGOUT
    })


    history.push("/");


  }

  const onProfileClick = () => {
    console.log(showUserProfile)
    setUserProfileVisibility(true);
    return;
  }
  const closeUserProfileDrawer = () => setUserProfileVisibility(false);

  const headerMenus = () => {

    return (
      <Menu>
        <Menu.Item onClick={onProfileClick}>
          Profiles
        </Menu.Item>
        <Menu.Item onClick={logOut}>
            Logout
        </Menu.Item>
      </Menu>)
  }
  const notifications = () => {

    return (
      state.notifications.length < 1 ?
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        :

        <Menu>
          <Menu.Item onClick={onProfileClick}>
            Profiles
        </Menu.Item>
          <Menu.Item>
            <span rel="noopener noreferrer" to="#" onClick={logOut}>
              Logout
        </span>
          </Menu.Item>
        </Menu>)
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar />
      <Layout>
        {configuration.maintenance_mode === '1' ? <MaintenanceBar /> : null}
        <Header style={{ background: '#fff', padding: 0 }} >
          <Row>
            <Col span={4} offset={18}>
              <Dropdown overlay={notifications} style={{ float: "right" }}>
                <a className="ant-dropdown-link" href="#">
                  <Icon type="bell" />
                </a>
              </Dropdown>

              <Divider type="vertical" />

              <Dropdown overlay={headerMenus} style={{ float: "right" }}>
                <a className="ant-dropdown-link" href="#">
                  {state.user.name} <Icon type="down" />
                </a>
              </Dropdown>
            </Col>
          </Row>
        </Header>

        <Content style={{ margin: '0 16px' }}>
          {/* <Breadcrumb itemRender={renderBreadcrumb} routes={adminRoutes} style={{ margin: '16px 0' }} /> */}
          <Drawer title="Profile" visible={showUserProfile} onClose={closeUserProfileDrawer}>
            <UserProfile />
          </Drawer>
          <Breadcrumb style={{ margin: '16px 0' }} >
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
          </Breadcrumb>
          <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
            {children}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>

          {configuration.app_name} © {(new Date()).getFullYear()}

        </Footer>
      </Layout>
    </Layout>
  )
}


export default withRouter(AdminLayout);