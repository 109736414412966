import React, { useState, useEffect,useContext } from "react";
import { Table, Button, Popconfirm, message } from "antd";
import GlobalStateContext from "../context";
import Webservice from "../classes/WebService";


const CustomTable = (props) => {
    const { columns, dataSource,rowSelection,stateSubjectKey,bordered,config} = props;
    const [state, dispatch] = useContext(GlobalStateContext);
    const [data, setData] = useState([])
    const [selectedIds,setSelectedIds] = useState([]);
    const [settings, setSettings] = useState({
        scrole:true
    })
    const [internalState, setInternalState] = useState( {
        data: [],
        pagination: {},
        loading: false,
      });
    
    const stateSubject = state[stateSubjectKey]; // I needed a way to make the useEffect hook refresh so i a using the subject
    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...internalState.pagination };
        pager.current = pagination.current;
        setInternalState({
            pagination: pager,
        });
        fetch({
            perPage: pagination.pageSize,
            page: pagination.current,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            ...filters,
        });
    };



    const defaultRowSelection = {
        onChange: (rowKey,rowData) => {
            setSelectedIds(rowKey);//rowKey because we are using the id as the keys
    },

}
   const  handleSearch = (()=>{


    })

    const fetch = (params = {}) => {
        setInternalState({ loading: true });
        let webservice = new Webservice();
        webservice.get({endpoint:dataSource,data: { perPage: 10,...params,}})
        .then(data => {
            const pagination = { ...internalState.pagination };
            // Read total count from server
            // pagination.total = data.totalCount;
            pagination.total = data.data.overallCount;
            setInternalState({
                loading: false,
                data: data.data.entries,
                pagination,
            });
        });
    };

    const deleteMultiple = ()=>{
        setInternalState({ loading: true });
        let webservice = new Webservice();
        webservice.post({endpoint:`${dataSource}/delete/multiple`, data:{entities:selectedIds}})
        .then(data => {
            setInternalState({
                loading: false
            });
            fetch();
            message.success("Item(s) deleted successfully");
        });
    }

    useEffect(()=>{
        fetch();   
    },
    [dataSource,stateSubject] // fetch only when datasource or state subject changes
    ) //

    return (
        <div>
            <div className="" hidden={selectedIds.length < 1}>
            <Popconfirm
                title="Are you sure delete all the selected items. Action cannot be undone!"
                onConfirm={deleteMultiple}
                okText="Yes, I don't care"
                cancelText="No"
            >
                <Button icon="delete" className="m-2" />
            </Popconfirm>
              
            </div>
            <Table

                columns={columns}
                rowKey={record => record.id}
                dataSource={internalState.data}
                pagination={internalState.pagination}
                loading={internalState.loading}
                rowSelection={rowSelection ? rowSelection : defaultRowSelection}
                onChange={handleTableChange}
                bordered = {bordered}
                scroll={{x:"100vw"}}
            />
        </div>
    );
}



export default CustomTable