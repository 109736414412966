import React, { useState, useEffect, useContext } from 'react'
import { Drawer, Tag, Divider, Col, Row, Skeleton, Timeline, Empty, Icon, Button, Modal, message, Radio, List } from 'antd'
import Webservice from '../../../../classes/WebService';
import GlobalStateContext from '../../../../context';
import ClientWebService from '../../../../classes/ClientWebService';
import defaultUserImage from '../../../../assets/images/user.png'
import verifiedImage from '../../../../assets/images/verified.svg'
import StreamWebService from '../../../../classes/StreamWebService';
import TransactionWebService from '../../../../classes/TransactionWebService';
import PackageWebService from '../../../../classes/PackageWebService';
import Example from '../../../../components/charts/Example';
import { Paper } from '@material-ui/core';


export default function ViewClient(props) {
    const { detail, setViewDetail } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [txLoading, setTxLoading] = useState(false);
    const [showPackageModal, setShowPackageModal] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);
    const [packages, setPackages] = useState([]);
    const [packagesRadioOptions, setPackagesRadioOptions] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState({});
    const [transactions, setTransactions] = useState([]);
    const [revenue, setRevenue] = useState(0);
    const [streams, setStreams] = useState([]);
    const context = useContext(GlobalStateContext);

    const colors = {
        'personal': 'blue',
        'business': 'green',
    }

    const fetchSubscriptions = async () => {
        if (detail.id) {
            setIsLoading(true);
            const service = new ClientWebService();
            const result = await service.getSubscriptions(detail.id);
            if (result.status === 200) {
                setSubscriptions(result.data);
            }
            setIsLoading(false);

        }
    }

    const fetchStreams = async () => {
        if (detail.id) {
            setIsLoading(true);
            const service = new StreamWebService();
            const result = await service.getStreams({ user: detail.id });
            if (result.status === 200) {
                setStreams(result.data.entries);
            }
            setIsLoading(false);

        }
    }
    const fetchTransactions = async () => {
        if (detail.id) {
            setTxLoading(true);
            const service = new TransactionWebService();
            const result = await service.getTransactions({ user: detail.id });
            if (result.status === 200) {
                const { data: { entries } } = result;
                setTransactions(entries);
                calculateRevenue(entries);
            }
            setTxLoading(false);

        }
    }

    /**
     * 
     * @param {array} txs 
     */
    const calculateRevenue = (txs) => {

        var total = 0;

        txs.forEach(element => {
            total += element.amount_paid > 0 ? element.amount_paid / element.xrate : 0;
        });
        setRevenue(total.toLocaleString());
        return total;

    }

    const cleanup = () => {
        setTransactions([]);
        setTxLoading(false);
        setRevenue(0);
        setSubscriptions([]);
    }

    useEffect(() => {
        fetchSubscriptions();
        fetchStreams();
        fetchTransactions();
        return cleanup;
    }, [detail])


    const onChangeSubClick = () => {
        //fetch packages;
        const pwb = new PackageWebService();
        pwb.getPackages().then(res => {
            console.log(res);
            const { data: { entries } } = res
            setPackages(entries);
            setPackagesRadioOptions(entries.map(pkg => ({ label: pkg.name, value: pkg.id })))
            setShowPackageModal(true);
        }).catch(err => message.error("Failed to fetch packages"));
    }

    const onPackageChange = (e) => {
        // console.log(e);
        const { target: { value } } = e;
        let selected;
        packages.forEach(elem => {
            if (elem.id === value) {
                selected = elem;
            }
        });
        setSelectedPackage(selected)
    }
    const onPackageChangeSave = (e) => {
        // console.log(e);
        const cwb = new ClientWebService();
        cwb.changeSubscription(detail.id, selectedPackage.id).
            then(res => {
                console.log(res);
                setSelectedPackage("");
                setShowPackageModal(false);
                message.success("User package updated successfully");
                fetchSubscriptions()
            }).catch(error => message.error(`operation failed:: ${error.message} `));
    }

    return (
        <div>
            <h4>{detail.name}</h4>
            <Button
                onClick={onChangeSubClick}
                type="primary"
            > Update Subscription</Button>

            <div className="row mt-5">
                <div className="col-md-6 col-sm-12 col-lg-6" style={{ background: "aliceblue" }}>
                    <div className="p-3 text-center">
                        <img src={detail.picture || defaultUserImage} className="circle" alt={detail.name} width={80} />
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-12 col-lg-6">
                            <p> <strong>Name:</strong>  {detail.name}</p>
                            <p> <strong>Account Type:</strong> <Tag color={colors[detail.account_type]}>{detail.account_type} </Tag> </p>
                            <p> <strong>Email:</strong>  {detail.email} {detail.email_verified_at && (<img src={verifiedImage} />)} </p>
                            <p> <strong>Revenue :</strong> USD {revenue}  </p>
                            <p> <strong>Joined </strong> : {(new Date(detail.createdAt)).toDateString()}</p>
                            <p> <strong>Last Login </strong> : {(new Date(detail.last_login)).toDateString()}</p>
                        </div>
                        <div className="col-md-6 col-sm-12 col-lg-6">

                            <p> <strong>Phone:</strong>  {detail.phone}</p>
                            <p> <strong>Package :</strong>  {detail.active_subscription ? detail.active_subscription.package_name : 'N/A'} </p>
                            <p> <strong>Remaining Data :</strong>  {detail.data_allocated - detail.data_used} GB</p>
                        </div>

                        <div className="col-md-6">
                            <Paper className="p-2 m-2" >
                                <h6>Data Usage</h6>
                                <Example
                                    x={{ key: "_id", label: "Time Period" }}
                                    y={{ key: "count_mb", label: "Data Used(MB)" }}
                                    data={detail.stats?.dataUsage} />
                            </Paper>

                        </div>
                        <div className="col-md-6">
                            <Paper className="p-2 m-2" >
                                <h6>Followers</h6>
                                <Example
                                    x={{ key: "_id", label: "Time Period" }}
                                    y={{ key: "count", label: "Followers" }}
                                    data={detail.stats?.followers} />
                            </Paper>

                        </div>
                        <div className="col-md-6">
                            <Paper className="p-2 m-2" >
                                <h6>View Trends</h6>
                                <Example
                                    x={{ key: "_id", label: "Time Period" }}
                                    y={{ key: "count", label: "Views" }}
                                    data={detail.stats?.viewsReceived} />
                            </Paper>

                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12 col-lg-6" >
                    <div className="row">
                        <div className="col-md-6">
                            <Paper className="p-2">

                                <h6>Subscriptions</h6>
                                <Skeleton active={true} loading={isLoading}>
                                    {subscriptions.length > 0 ?
                                        <Timeline>
                                            {Array.isArray(subscriptions) ? subscriptions.reverse().map((item) => {
                                                return (
                                                    <Timeline.Item key={item.id}>
                                                        <Tag> {item.package_name}</Tag>
                                                        <div>
                                                            <div>starts {(new Date(item.starts_at)).toDateString()}</div>
                                                            <div> ends  {(new Date(item.ends_at)).toDateString()}</div>
                                                            <div> Data  {Math.round( item.data_usage)} / {item.features.data_allocated} GB</div>

                                                        </div>
                                                    </Timeline.Item>
                                                )
                                            })
                                                : null}
                                        </Timeline>
                                        : "No data"}
                                </Skeleton>

                            </Paper>
                        </div>
                        <div className="col-md-6  ">
                            <Paper className="p-2">
                                <h6>Streams </h6>
                                <Skeleton active={true} loading={isLoading}>
                                    {streams.length > 0 ?
                                        <Timeline>
                                            {Array.isArray(streams) ? streams.reverse().map((strm) => {

                                                return (
                                                    <Timeline.Item>
                                                        <Tag> {strm.title}</Tag>
                                                        <div>
                                                            <div>created {(new Date(strm.createdAt)).toDateString()}</div>
                                                            {strm.updatedAt && (
                                                                <div>last updated  {(new Date(strm.updatedAt)).toDateString()}</div>
                                                            )}

                                                        </div>
                                                    </Timeline.Item>
                                                )
                                            })
                                                : null}
                                        </Timeline>
                                        : "No data"}
                                </Skeleton>
                            </Paper>

                        </div>
                    </div>

                </div>
            </div>



            <Modal
                visible={showPackageModal}
                onCancel={_ => setShowPackageModal(false)}
                onOk={onPackageChangeSave}
                okType="danger"
            >
                <div className>
                    <label>Select Prefered package</label>
                    <Radio.Group
                        options={packagesRadioOptions}
                        onChange={onPackageChange}
                        value={selectedPackage.id}
                        optionType="button"
                        buttonStyle="solid"
                    />
                </div>

                <div hidden={!selectedPackage.id}>
                    {selectedPackage && (
                        <List
                            dataSource={selectedPackage.features ? Object.keys(selectedPackage.features) : []}
                            renderItem={itm =>
                                <List.Item key={itm}>
                                    <List.Item.Meta
                                        title={itm}
                                    />
                                    {selectedPackage.features[itm]}
                                </List.Item>
                            }
                        />
                    )
                    }
                </div>

            </Modal>
        </div>
    )
}
