import React from 'react'
import {  Tag,Empty } from 'antd'


function SubscriptionMeta(props) {
    const { meta } = props

    return (
        <div>
            <p> <strong>Package :</strong>  <Tag> {meta.package.name} </Tag></p>
        </div>
    )
}

export default function MetaData(props) {
    const { type, meta } = props;

    switch (type) {
        case 'subscription':
            return <SubscriptionMeta meta={meta} />;

        default:
            return <Empty />;
    }

}

