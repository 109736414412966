import React, { Suspense, useEffect } from 'react';
import useGlobalState from "./store"
import GlobalStateContext from './context';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from './pages/front/Home.js';
import Admin from './pages/admin/AdminIndex';
import ProtectedRoute from './routers/ProtectedRoute';
import Login from './pages/front/Login.js';
import './App.css';
import { Icon, message } from 'antd';
import AuthService from './classes/AuthService';
import { LOGIN, SET_ACTIVE_USER, SAVE_ITEM,SET_ITEM } from './store/actions/types';
import ConfigurationWebService from './classes/ConfigurationWebService';
import Webservice from './classes/WebService';
import StreamWebService from './classes/StreamWebService';

function App() {
  const [state, dispatch] = useGlobalState()
  const stateUser = state.user;

  const loading = () => (<Icon type="loading" />)
  const token = localStorage.getItem('token');

  const fetchConfigs = async () => {
    const webservice = new ConfigurationWebService()
    const values = await webservice.getConfigValues().then(res => res.data)
    dispatch({
      type: SAVE_ITEM,
      payload: ['configuration', values]
    })
    return values
  }

  const fetchStreamDestinations = async () => {
    const webservice = new StreamWebService()
    const values = await webservice.getDestinations().then(res => res.data)
    dispatch({
      type: SET_ITEM,
      payload: ['stream_destinations', values]
    })
    return values
  }
  const initializeApp = async () => {

    //check store if user data is set
    if (!state.user) {
      // if not check localstorage for token
      //if not log user out
      if (token) {
        //request for user info
        const user = await (new AuthService()).getUserProfile().then(res => res.data);
        dispatch(
          {
            type: SET_ACTIVE_USER,
            payload: user
          }
        )
      }


    } else {
      fetchConfigs();
    }

  }

  useEffect(() => {
    initializeApp();
    fetchStreamDestinations();
  }, [state.user]);

  return (
    <GlobalStateContext.Provider value={[state, dispatch]} >
      <div className="App">
        <Router>
          <Suspense fallback={loading}>
            <Switch>
              <ProtectedRoute path="/admin">
                <Admin />
              </ProtectedRoute>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </Suspense>
        </Router>
      </div>
    </GlobalStateContext.Provider>
  );
}

export default App;


