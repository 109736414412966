import React, { useContext, useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Button, Divider, Tag, Drawer, Icon, Popconfirm, message } from "antd";

import GlobalStateContext from '../../../../../context';
import { FETCH_ITEMS } from '../../../../../store/actions/types'

import CreateConfiguration from './CreateConfiguration';
import ViewConfiguration from './ViewConfiguration';
import EditConfiguration from './EditConfiguration';
import CustomTable from '../../../../../components/CustomTable';
import ConfigurationWebService from '../../../../../classes/ConfigurationWebService';

let onViewButtonClick;
let onEditClick;
let onDeleteConfirm;
let onDeleteCancel;
let activeUser;

const renderRole = (role) => {
    return role ? <Tag > {role.name}</Tag> : null
}
const renderAccountType = (actype) => {
    return <Tag > {actype}</Tag>
}
const columns = [
    {
        key: 'label',
        title: 'Name',
        dataIndex: 'label',
        render: text => text,
        ellipsis: true,
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        render: renderAccountType,
    },

    {
        title: 'value',
        dataIndex: 'value',
        key: 'value',
        render: text => text,
        ellipsis: true,
    },
    {
        title: 'Actions',
        // fixed: 'right',
        render: (entity) => (<div>
            <Divider type="vertical" />
            <Button disabled={!entity.mutable} type="link" onClick={onEditClick.bind(this, entity)}>Edit</Button>
            <Divider type="vertical" />
            <Popconfirm  disabled={!entity.mutable}
                title={`Are you sure delete ${entity.name}?`}
                onConfirm={onDeleteConfirm.bind(this, entity)}
                onCancel={onDeleteCancel}
                okText="Yes"
                cancelText="No"
            >

                <Button disabled={!entity.mutable} type="link">Delete</Button>
            </Popconfirm>
        </div>)
    }
]

function ConfigurationList(props) {
    const [state, dispatch] = useContext(GlobalStateContext);
    const [isLoading, setIsLoading] = useState(false)
    const [viewDetail, setViewDetail] = useState(false);
    const [createFormVisibility, setCreateFormVisibility] = useState(false);
    const [editFormVisibility, setEditFormVisibility] = useState(false);
    const [selectedConfig, setSelectedConfig] = useState();
    const [selectedConfigs, setSelectedConfigs] = useState([]);

    activeUser = state.user;
    const { users } = state;

    const onClickNewButton = () => {
        setCreateFormVisibility(true)
    }

    const onCloseNewDrawer = () => {
        setCreateFormVisibility(false)
    }
    const onCloseEditDrawer = () => {
        setEditFormVisibility(false)
    }
    const closeViewDrawer = () => {
        setViewDetail(false);
    }
    onViewButtonClick = (user) => {
        setViewDetail(user);
    }
    onEditClick = (user) => {
        setSelectedConfig(user);
        setEditFormVisibility(true)
    }

    onDeleteCancel = () => {

    }

    onDeleteConfirm = (entity) => {
        setIsLoading(true);
        const  service = new ConfigurationWebService();
        service.deleteConfig(entity.ref_id)
            .then(res => {
                message.success(`${entity.label} deleted successfully`);
                fetchData()
            })
            .catch(err => {
                message.error(`Failed to delete ${entity.label}`);
                console.log(err)
            }).finally(() => setIsLoading(true))
    }

    const fetchData = async () => {
        setIsLoading(true);
        const service = new ConfigurationWebService();
        const result = await service.getConfigs();
        if (result.status == 200) {
            dispatch({
                type: FETCH_ITEMS,
                payload: ['configuration_list',result.data.entries]
            })
        }
        setIsLoading(false);
    }

    useEffect(() => {
        // fetchData();      
    }, [])
    const buttonStyle = {
        position: "relative",
        float: "right",
        button: "50px",
        margin: "10px 10px"

    }

    return (
        <div>
            <div >
                <h5>
                    Configurations
                    <Button style={buttonStyle} type='dashed' onClick={onClickNewButton}>
                        <Icon type="setting" /> Create Variable
                         </Button>
                </h5>
            </div>
            <div>
                {/* <Table rowKey={record => record.id} rowSelection={rowSelection}  columns={columns} dataSource={users} loading={isLoading} ></Table> */}
                <CustomTable
                    rowKey={record => record.id}
                    // rowSelection={rowSelection}  
                    columns={columns}
                    selected={selectedConfigs}
                    dataSource="/configurations/"
                    loading={isLoading}
                    stateSubjectKey='configuration_list'
                />

            </div>
            <Drawer
                title="Add Config"
                width={720}
                bodyStyle={{ paddingBottom: 80 }}
                visible={createFormVisibility}
                onClose={onCloseNewDrawer}  >
                <CreateConfiguration onClose={onCloseNewDrawer} refreshListPage={fetchData} />
            </Drawer>
            <Drawer
                title={`Update ${selectedConfig ? selectedConfig.label : ''} detail`}
                width={720}
                onClose={onCloseEditDrawer}
                visible={editFormVisibility}
                bodyStyle={{ paddingBottom: 80 }}
            >
                <EditConfiguration onClose={onCloseEditDrawer} refreshListPage={fetchData} entity={selectedConfig} setSelectedConfig={setSelectedConfig} />
            </Drawer>

            <Drawer visible={viewDetail ? true : false} onClose={closeViewDrawer} width={'40vw'} >
                <ViewConfiguration detail={viewDetail} setViewDetail={setViewDetail} />
            </Drawer>

        </div>


    )
}

export default withRouter(ConfigurationList)
