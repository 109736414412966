import React, { useState,useEffect } from 'react'
import {  Tag, Divider, Col, Row, Skeleton, Timeline, Empty } from 'antd'

import ClientWebService from '../../../../../classes/ConfigurationWebService';

export default function ViewClient(props) {
    const { detail, setViewDetail } = props;

    const [isLoading,setIsLoading] = useState(false);
    const [subscriptions,setSubscriptions] = useState([]);

    const onClose = () => {
        setViewDetail(false)
    }
    const colors = {
        'personal' : 'blue',
        'business' : 'green',
    }

    const fetchSubscriptions = async ()=>{
        if(detail.id){
            setIsLoading(true);
            const service = new ClientWebService();
            const result = await service.getSubscriptions(detail.id);
            if(result.status ==  200){
                setSubscriptions(result.data);
            }
            setIsLoading(false);

        }
    }

    useEffect(()=>{
        fetchSubscriptions();      
    },[detail])

    
    return (
        <div>
            <h4>{detail.name}</h4>
            <Row>
                <Col>
                    <p> <strong>Name:</strong>  {detail.name}</p>
                    <p> <strong>Account Type:</strong> <Tag color={colors[detail.account_type]}>{detail.account_type} </Tag> </p>
                    <p> <strong>Email:</strong>  {detail.email}</p>
                    <p> <strong>Phone:</strong>  {detail.phone}</p>
                </Col>
                <Divider />
                <p> <strong>Joined </strong> : {(new Date(detail.createdAt)).toDateString()}</p>
                <Divider />
            </Row>

            <div>
                <h5>Subscriptions</h5>
                <Skeleton  active={true} loading={isLoading}>
                    {subscriptions.length > 0  ? 
                    <Timeline>
                        { Array.isArray(subscriptions) ? subscriptions.reverse().map((item)=>{
                            return <Timeline.Item> <Tag> {item.package_name}</Tag> 
                            starts { (new Date(item.starts_at)).toDateString()}
                             ends  { (new Date(item.ends_at)).toDateString()}
                             </Timeline.Item>
                        })
                         : null}
                    </Timeline>
: <Empty/>}
                </Skeleton>
            </div>
        </div>
    )
}
