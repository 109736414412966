import React from 'react'
import { Tag, Divider } from 'antd'

export default function ViewAdminUser(props) {
    const { detail, setViewDetail } = props;


    return (
        <div>
            <h4>{detail.name}</h4>

            <div>
                <p> <strong>Name:</strong>  {detail.name}</p>
                <p> <strong>Email:</strong>  {detail.email}</p>
                {detail.role ?  <p> <strong>Role:</strong> <Tag>{detail.role.name}</Tag></p> : null}
                
               
                <Divider />
                <p> <strong>Joined </strong> : {(new Date(detail.createdAt)).toDateString()}</p>
                <Divider />
            </div>
        </div>
    )
}
