import React, { useContext, useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Button, Divider, Tag, Icon, Popconfirm, message, Form, Col, Row, Input, Select, Upload, Switch, Tabs, InputNumber } from "antd";
import NewData from '../../../../components/admin/NewData';
import GlobalStateContext from '../../../../context';
import ConfigurationWebService from '../../../../classes/ConfigurationWebService';
import { SAVE_ITEM } from '../../../../store/actions/types';
import { sanitizeLabel } from '../../../../utils';
import ConfigurationList from './components/ConfigurationList';
import PropTypes from 'prop-types';
import FieldGenerator from '../../../../components/FieldGenerator';

const Option = Select.Option

const renderPerms = (items) => {

    return items.map(item => <Tag key={item.ref_id} > {item.name}</Tag>)
}





const ConfigurationsForm = Form.create()(
    (props) => {

        const { getFieldDecorator } = props.form;
        const { item, refreshListPage, onClose } = props;
        const [formValues, setFormValues] = useState({});
        const [configItems, setConfigItems] = useState([]);
        const [isLoading, setLoading] = useState(false);
        const webservice = new ConfigurationWebService();
        const [state, dispatch] = useContext(GlobalStateContext)
        const tags = Object.keys(configItems);


        const fetch = async () => {
            try {
                const configs = await webservice.getConfigs().then(res => res.data.entries);

                //group configs based on tags
                const grouped = {};
                configs.forEach(element => {
                    const tags = Object.keys(grouped);
                    if (tags.indexOf(element.tag) === -1) {
                        grouped[element.tag] = [element];
                    }
                    else {
                        grouped[element.tag].push(element);
                    }
                });

                setConfigItems(grouped);
            } catch (error) {
                console.log(error);
                message.error(`Failed to fetch configs Items, ${error.message}`);
            }
        }

        useEffect(() => {
            fetch();
        }, [])


        const onSubmit = async (e) => {
            e.preventDefault();
            if (formValues) {
                setLoading(true);
                await webservice
                    .update({ data: formValues })
                    .then(resp => {
                        dispatch({
                            type: SAVE_ITEM,
                            payload: ['configuration', resp.data]
                        });
                        message.success("Configurations updated successfully");
                    })
                    .catch(err => {
                        message.error(`Could not save. ${err.message} `);
                    });

                setLoading(false);

            }

        }


        const onFieldChange = (e, e2 = null) => { // e2 cuz antDesign Switch component parses the value as a first param and the event as second     
            if (e2) {
                e2.target.value = e === true ? '1' : '0';
                e = e2;
            }
            const elementName = e.target.name
            const elementValue = e.target.value
            setFormValues({ ...formValues, [elementName]: elementValue })
        }

        const buildForm = (item) => {
            let formElement;
            const elemType = item.type
            switch (elemType.toLowerCase()) {
                case 'text':
                    formElement = (<Form.Item label={item.label}>
                        <Input name={item.ref_id} defaultValue={item.value}
                            onChange={onFieldChange}
                            placeholder={item.placeholder || `Please enter item ${item.label}`}
                        />
                    </Form.Item>)
                    break;
                case 'number':
                    formElement = (<Form.Item label={item.label}>
                        <InputNumber name={item.ref_id} defaultValue={item.value}
                            onChange={onFieldChange}
                            placeholder={item.placeholder || `Please enter item ${item.label}`}
                        />
                    </Form.Item>)
                    break;
                case 'decimal':
                    formElement = (<Form.Item label={item.label}>
                        <InputNumber name={item.ref_id} defaultValue={item.value}
                            onChange={onFieldChange}
                            placeholder={`${item.placeholder || ""}`}
                            step={0.1}
                        />
                    </Form.Item>)
                    break;
                case 'radio':
                    formElement = (<Form.Item label={item.label}>
                        <Switch name={item.ref_id} defaultChecked={item.value === '1' ? true : false}
                            onChange={onFieldChange} />
                    </Form.Item>)
                    break;
                default:
                    formElement = (<Form.Item label={item.label}>
                        <Input name={item.ref_id} defaultValue={item.value}
                            placeholder={item.placeholder} onChange={onFieldChange} />
                    </Form.Item>)

            }

            return formElement;
        }

        const buildTabPane = (tag) => {

            return (<Tabs.TabPane tab={sanitizeLabel(tag).toUpperCase()} key={tag}>

                <div key={tag} className="row">
                    {
                        configItems[tag].map(item => {
                            return (
                                <div key={item.ref_id} className="col col-md-4">
                                    {<FieldGenerator 
                                    type={item.type}
                                     onChange={onFieldChange} 
                                     name={item.ref_id}
                                      defaultValue={item.value} 
                                      label={item.label} 
                                       />}
                                </div>
                            )
                        })

                    }
                </div>
            </Tabs.TabPane>)
        }

        return (
            <div>
                <Form layout="vertical" onSubmit={onSubmit} >
                    {
                        tags.length > 0 ?
                            <Tabs defaultActiveKey={configItems ? tags[0] : null} >
                                {
                                    tags.map(buildTabPane)
                                }
                            </Tabs>
                            :
                            null
                    }
                </Form>
                <div

                >

                    <Button disabled={isLoading} type="button" onClick={onSubmit} type="primary">
                        {isLoading ? <Icon type="loading" /> : "Save"}
                    </Button>
                </div>
            </div>
        )
    }
)
const ConfigurationPage = function (props) {
    return (
        <ConfigurationsForm />
    )
}

export default ConfigurationPage
