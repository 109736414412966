import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Divider, Tag, Drawer, Icon, Popconfirm, message } from "antd";
import GlobalStateContext from '../../../context';
import {  FETCH_ITEMS } from '../../../store/actions/types'

import ViewCoupon from './components/ViewCoupon';
import EditCoupon from './components/EditCoupon';
import CustomTable from '../../../components/CustomTable';
import CreateCoupon from './components/CreateCoupon';
import CouponWebService from '../../../classes/CouponWebService';
import moment from "moment"




let onViewButtonClick;
let onEditClick;
let onDeleteConfirm;
let onDeleteCancel;
let activeUser;

const renderUsr = (item) => {

    return  item && <Tag key={item.id} > {item.name}</Tag>
}
const columns = [
    {
    
        title: 'Code',
        dataIndex: 'code',
        render: text => text,
    },
    {
    
        title: 'Value',
        dataIndex: 'value',
        render: text => <> {text}   </>,
    },
    {
    
        title: 'Value Type',
        dataIndex: 'type',
        render: text => <> {text}   </>,
    },
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        
    },
    {
        title: 'From',
        dataIndex: 'starts_at',
        key: 'starts_at',
        render: _ => moment(_).format("MMM Do YY"),
        
    },
    {
        title: 'To',
        dataIndex: 'ends_at',
        key: 'ends_at',
        render: _ => moment(_).format("MMM Do YY"),
    },

    {
        title: 'Assigned To',
        dataIndex: 'assigned_to',
        key: 'assigned_to',
        render: renderUsr,
        ellipsis: true,
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: st => <Tag>{['inactive','active'][st]}</Tag>,
        ellipsis: true,
    },
    {
        title: 'Actions',
        // fixed: 'right',
        render: (item) => (<div>
            <Button type="link" onClick={onViewButtonClick.bind(this,item)}>View</Button>
            <Divider type="vertical" />
            <Button type="link" onClick={onEditClick.bind(this,item)}>Edit</Button>
            <Divider type="vertical" />
            <Popconfirm disabled={item.ref_id == 'super_admin'}
             title={`Are you sure delete ${item.name}?`}
             onConfirm={onDeleteConfirm.bind(this,item)}
             onCancel={onDeleteCancel}
             okText="Yes"
             cancelText="No"
             
            >

            <Button disabled={item.ref_id == 'super_admin'} type="link">Delete</Button>
            </Popconfirm>
        </div>)
    }
]

 function CouponIndexPage(props) {
    const couponWebService = new CouponWebService()
    const [state, dispatch] = useContext(GlobalStateContext);
    const [isLoading,setIsLoading] = useState(false)
    const [selectedItem,setSelectedItem] = useState();
    const [selectedItems,setSelectedItems] = useState([]);
    const [showCreateForm,setShowCreateForm] = useState(false);
    const [showViewDrawer,setShowViewDrawer] = useState(false);
    const [showEditDrawer,setShowEditDrawer] = useState(false);

    activeUser = state.user;
    const {users} = state;
 
    const onClickNewButton = () => {
        setShowCreateForm(true);
    }

     onViewButtonClick = (item) => {
        setSelectedItem(item);
        setShowViewDrawer(true);
    }
     onEditClick = (item) => {
        setSelectedItem(item);
        setShowEditDrawer(true);
    }

    onDeleteCancel = ()=>{
            
    }

    onDeleteConfirm = (item)=>{
        setIsLoading(true);
        couponWebService.deleteCoupon(item.id)
        .then(res=>{
            message.success(`${item.name} deleted successfully`);
            fetchData()
        })
        .catch(err=> {
            message.error(`Failed to delete ${item.name}`);
        }).finally(()=> setIsLoading(true))
    }

    const fetchData = async ()=>{
        setIsLoading(true);
        const result = await couponWebService.getCoupons({sort:{ends_at: -1,status: -1}});
        if(result.status ===  200){
            dispatch({
                type: FETCH_ITEMS,
                payload: ['coupons',result.data.entries]
            })
        }
        setIsLoading(false);
    }

    useEffect(()=>{
        fetchData();      
    },[])

    const buttonStyle = {
        position: "relative",
        float: "right",
        button: "50px",
        margin: "10px 10px"

    }

    return (
        <div>
            <div >
                <h5>
                     Coupons
                    <Button style={buttonStyle} type='dashed' onClick={onClickNewButton}>
                         <Icon type="user-add"/> New 
                         </Button>
                </h5>
            </div>
            <div>
                <CustomTable 
                rowKey={record => record.ref_id} 
                // rowSelection={rowSelection}  
                columns={columns} 
                selected = {selectedItems}
                dataSource="/coupons/" 
                loading={isLoading}
                stateSubjectKey='coupons'
                />
            
            </div>
            <Drawer visible={showCreateForm} onClose={()=>setShowCreateForm(false)} width={'40vw'} >
                <CreateCoupon onClose={setShowCreateForm} refreshListPage={fetchData}/> 
            </Drawer>
            <Drawer visible={showEditDrawer} onClose={()=>setShowEditDrawer(false)} width={'40vw'} >
                <EditCoupon  refreshListPage={fetchData} onClose={()=>setShowEditDrawer(false)} item={selectedItem}/>
            </Drawer>
            
           
            <Drawer visible={showViewDrawer} onClose={()=>setShowViewDrawer(false)} width={'40vw'} >
                <ViewCoupon item={selectedItem} title="Coupon" />
            </Drawer>

        </div>


    )
}

export default withRouter(CouponIndexPage)
