import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Tag, Drawer, Icon, message, Row, Col, Statistic} from "antd";
import GlobalStateContext from '../../../context';
import {FETCH_ITEMS } from '../../../store/actions/types'
import ViewTransaction from './components/ViewTransaction';
import CustomTable from '../../../components/CustomTable';
import TransactionWebService from '../../../classes/TransactionWebService';

let onViewButtonClick;


const statusTypes = {'pending': '', 'confirmed':'green', 'failed':'red'}

const renderType = (actype) => {
    return <Tag > {actype}</Tag>
}


const columns = [
    {
        title: 'TX #',
        key: 'id',
        dataIndex: 'id',
        render: text => text,
        ellipsis: true,
    },
    {
        title: 'Method',
        key: 'payment_method',
        dataIndex: 'payment_method',
        render: renderType,
    },
    {
        title: 'Currency',
        dataIndex: 'currency_code',
        key: 'currency_code',
        render: currency_code => currency_code || 'N/A',
        ellipsis: true,
    },
    {
        title: 'Ex. Rate',
        dataIndex: 'xrate',
        key: 'xrate',
        render: xrate => xrate || 'N/A',
        ellipsis: true,
    },

    {
        title: 'Expected',
        dataIndex: 'expected_amount',
        key: 'expected_amount',
        render: amount => amount.toLocaleString(),
        ellipsis: true,
    },
    {
        title: 'Paid',
        dataIndex: 'amount_paid',
        key: 'amount_paid',
        render: amount => amount.toLocaleString(),
        ellipsis: true,
    },

    {
        title: 'By',
        dataIndex: 'user',
        key: 'user',
        render: usr => usr ? usr.name : null,
        ellipsis: true,
    },
    {
        title: 'Status',
        render: item => (
            <div>
                <Tag color={statusTypes[item.status]}> {item.status} </Tag>
            </div>
        )

    },
    {
        title: 'Actions',
        // fixed: 'right',
        render: (item) => (<div>
            <Button type="link" onClick={onViewButtonClick.bind(this, item)}>View</Button>
        </div>)
    }
]

function TransactionIndexPage(props) {
    const [state, dispatch] = useContext(GlobalStateContext);
    const [isLoading, setIsLoading] = useState(false)
    const [viewDetail, setViewDetail] = useState(false);
    const [createFormVisibility, setCreateFormVisibility] = useState(false);
    const [editFormVisibility, setEditFormVisibility] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const [selectedItems, setSelectedItems] = useState([]);
    const [txTotalValue, setTxTotalValue] = useState(0);
    const [txTotal, setTxTotal] = useState(0);
    const [confirmedTxValue, setConfirmedTxValue] = useState(0);
    const [confirmedTx, setConfirmedTx] = useState(0);


    
    const closeViewDrawer = () => {
        setViewDetail(false);
    }
    onViewButtonClick = (item) => {
        setViewDetail(item);
    }


    const TransactionStats = (props) => {
        return (
            <div style={{ margin: "15px" }}>

                <Row gutter={16}>
                    <Col span={8}>
                        <Statistic title=" Expected Value (USD)" value={(props.txTotalValue.toLocaleString())} prefix={<Icon type="card" />} />
                    </Col>
                    <Col span={8}>
                        <Statistic title="Confirmed Value (USD)" value={props.confirmedTxValue.toLocaleString()} />
                    </Col>
                    <Col span={8}>
                        <Statistic title="Confirmed Counts / Total Count" value={props.confirmedTx} suffix={`/ ${props.txTotal}`} />
                    </Col>
                </Row>
            </div>
        );
    }


    const sumTxValue = (entries) => {
        let sum = 0.00;

        entries.forEach(tx => {
            sum += tx.expected_amount / (tx.xrate || 1)
        });

        setTxTotalValue(sum);
    }
    const sumConfirmedValue = (entries) => {
        let total = entries.length;
        setTxTotal(total);
        let sum = 0.00;
        let num = 0;

        entries.forEach(tx => {
            if (tx.status === 'confirmed') {
                num ++;
                sum = parseFloat(sum) + parseFloat(tx.amount_paid / (tx.xrate || 1));
            }
        });

        setConfirmedTx(num);
        setConfirmedTxValue(sum);
        
    }

    const fetchData = async () => {
        setIsLoading(true);
        const service = new TransactionWebService();
        const result = await service.getTransactions();
        if (result.status == 200) {
            let entries = result.data.entries;
            dispatch({
                type: FETCH_ITEMS,
                payload: ['transactions', entries]
            })
            sumTxValue(entries)
            sumConfirmedValue(entries)
            console.log("treasdd", txTotalValue.toLocaleString())

        }
        setIsLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, [])
    const buttonStyle = {
        position: "relative",
        float: "right",
        button: "50px",
        margin: "10px 10px"

    }

    return (
        <div>
            <div >
                <h5>
                    Transactions
                </h5>
            </div>
            {<TransactionStats confirmedTx={confirmedTx} confirmedTxValue={confirmedTxValue.toFixed(2)} txTotal={txTotal} txTotalValue={txTotalValue.toFixed(2)} />}
            <div>
                {/* <Table rowKey={record => record.id} rowSelection={rowSelection}  columns={columns} dataSource={users} loading={isLoading} ></Table> */}
                <CustomTable
                    rowKey={record => record.id}
                    // rowSelection={rowSelection}  
                    columns={columns}
                    selected={selectedItems}
                    dataSource="/transactions/"
                    loading={isLoading}
                    stateSubjectKey='transactions'
                    bordered= {true}
                />

            </div>

            <Drawer visible={viewDetail ? true : false} onClose={closeViewDrawer} width={'40vw'} >
                {viewDetail ? <ViewTransaction detail={viewDetail} setViewDetail={setViewDetail} /> : null}
            </Drawer>

        </div>


    )
}

export default withRouter(TransactionIndexPage)
