import React, { useState, useContext, useEffect } from 'react';
import {  Form, Button, Col, Row, Input, Select, Icon, message, Switch, InputNumber, DatePicker } from 'antd';
import GlobalStateContext from '../../../../context'
import Webservice from '../../../../classes/WebService';
import CouponWebService from '../../../../classes/CouponWebService';
import moment from "moment";


const { Option } = Select;

const EditCoupon = function (props) {
  const webservice = new Webservice();
  const couponWebService = new CouponWebService();
  const { getFieldDecorator } = props.form;
  const { item,refreshListPage,onClose} = props;

  const [name, setName] = useState('');
  const [types, setTypes] = useState([]);
  const [users, setUsers] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [state, dispatch] = useContext(GlobalStateContext);


  const fetch = async () => {
    try {
      webservice.get({ endpoint: "users/" })
        .then(res => res.data.entries)
        .then(items => setUsers(items));
    } catch (error) {
      message.error("Failed to fetch perms");
    }
  }
  const fetchTypes = async () => {
    try {
      couponWebService.getCouponTypes()
        .then(res => res.data.entries)
        .then(items => setTypes(items));
    } catch (error) {
      message.error("Failed to fetch types");
    }
  }

  useEffect(() => {
    fetch();
    fetchTypes();
  }, [])


  const onSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {

        setLoading(true);
        await couponWebService
          .save({ data: values, id: item.id })
          .then(resp => {
            onClose();
            refreshListPage();
            message.success("Coupon saved successfully");
          })
          .catch(err => {
            message.error(`Could not save. ${err.message} `);
          });

        setLoading(false);

      }
    })
  }


  const nameOnChange = (e) => {
    setName(e.target.value);
  }

  return (
    <div>

      <Form layout="vertical" >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Coupon Code">
              {getFieldDecorator('code', {
                  initialValue: item.code ,
                rules: [{ required: true, message: 'Please enter  code' }],
              })(<Input disabled placeholder="11AABDC" />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Coupon Title">
              {getFieldDecorator('title', {
                  initialValue: item.title ,
                rules: [{ required: true, message: 'Please enter title' }],
              })(<Input placeholder="Please enter  name" />)}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Coupon  Value">
              {getFieldDecorator('value', {
                  initialValue: item.value ,
                rules: [{ required: true, message: 'Please enter code' }],
              })(<InputNumber placeholder="10" />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Value Type">
              {getFieldDecorator('type', {
                 initialValue: item.type ,
                rules: [{ required: true, message: 'Please select coupon type' }],
              })(
                <Select showSearch>
                  {types.map((_type) => <Option key={_type} value={_type}>{_type}</Option>)}
                </Select>,
              )}
            </Form.Item>
          </Col>


          <Col span={12}>
            <Form.Item label="Usage limit">
              {getFieldDecorator('limit', {
                 initialValue: item.limit ,
                rules: [],
              })(<InputNumber placeholder="" alt="Empty or 0 for unlimited" />)}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Starts At">
              {getFieldDecorator('starts_at', {
                initialValue: item.starts_at && moment(item.starts_at),
                rules: [],
              })(<DatePicker   />)}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Ends At">
              {getFieldDecorator('ends_at', {
                 initialValue: item.ends_at && moment(item.ends_at),
                rules: [],
              })(<DatePicker />)}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Assign To">
              {getFieldDecorator('assigned_to', {
                initialValue:  item.assigned_to && item.assigned_to._id ,
                rules: [],
              })(
                <Select showSearch>
                  {users.map((usr) => <Option key={usr.id} value={usr.id}>{usr.name}</Option>)}
                </Select>,
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div
        style={{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
        }}
      >
        <Button onClick={onClose} style={{ marginRight: 8 }}>
          Cancel
            </Button>
        <Button disabled={isLoading} type="button" onClick={onSubmit} type="primary">
          {isLoading ? <Icon type="loading" /> : "Submit"}
        </Button>
      </div>

    </div>
  )
}


export default Form.create()(EditCoupon);