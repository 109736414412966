import React, { useState, useContext } from 'react';
import { Drawer, Form, Button, Col, Row, Input, Select, Divider, Icon, Upload } from 'antd';
import GlobalStateContext from '../../context'
import { SHOW_NEW_DATA_FORM ,SAVE_USER} from '../../store/actions/types';

const { Option } = Select;


const uploadButton = () => {
  return (<div>
    <Icon type="plus" />
    <div className="ant-upload-text">Upload</div>
  </div>)
}
const NewData = function (props) {

  const { getFieldDecorator } = props.form;
  const [name, setName] = useState('');
  const [state, dispatch] = useContext(GlobalStateContext);


  const onClose = () => {
    dispatch({
      type: SHOW_NEW_DATA_FORM,
      payload: false
    });
  }

  const onSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        dispatch({
          type: SAVE_USER,
          payload: values
        })
        console.log(values);
      }
    })
  }


  const nameOnChange = (e) => {
    console.log('change')
    setName(e.target.value);
  }

  return (
    <div>
      <Drawer
        title=" Entry"
        width={720}
        onClose={onClose}
        visible={state.showNewDataForm}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form layout="vertical" >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Name">
                {getFieldDecorator('name', {
                  rules: [{ required: true, message: 'Please enter  name' }],
                })(<Input placeholder="Please enter  name" onChange={nameOnChange} />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Tag(s)">
                {getFieldDecorator('tag', {
                  rules: [{ required: true, message: 'Please select a tag' }],
                })(
                  <Select mode="multiple" placeholder="Please select a tag">
                    {state.userAccountTypes.map((tag) => <Option value={tag}>{tag}</Option>)}
                    {/* <Option value="mao">Maomao Zhou</Option> */}

                  </Select>,
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Description">
                {getFieldDecorator('description', {
                  rules: [
                    {
                      required: true,
                      message: 'Description is required',
                    },
                  ],
                })(<Input.TextArea rows={4} placeholder={`Something about ${name ? name : ' the '}`} />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Symptoms">
                {getFieldDecorator('symptoms', {
                  rules: [
                    {
                      required: true,
                      message: 'please enter  symptoms',
                    },
                  ],
                })(<Input.TextArea rows={4} placeholder="please enter  symptoms" />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Diagnosis">
                {getFieldDecorator('diagnosis', {
                  rules: [
                    {
                      required: true,
                      message: 'please enter  Diagnosis',
                    },
                  ],
                })(<Input.TextArea rows={4} placeholder="please enter  Diagnosis" />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Upload
                listType="picture-card"

              >
                {uploadButton()}
              </Upload>
            </Col>
          </Row>
          <Divider></Divider>
          <Row>
            <Col span={12}>
              <Form.Item label="Status">
                {getFieldDecorator('status', {
                  rules: [{ required: true, message: 'Please choose the status' }],
                })(
                  <Select placeholder="Please choose the status">
                    <Option value="0">Inactive</Option>
                    <Option value="1">Active</Option>
                  </Select>,
                )}
              </Form.Item>
            </Col>
          </Row>

        </Form>
        <div
          style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
          }}
        >
          <Button onClick={onClose} style={{ marginRight: 8 }}>
            Cancel
            </Button>
          <Button onClick={onSubmit} type="primary">
            Submit
            </Button>
        </div>
      </Drawer>
    </div>
  )
}


export default Form.create()(NewData);