import React, { useState, useContext, useEffect } from 'react';
import {  Form, Button,Input, Select, Icon, message, Switch } from 'antd';
import Webservice from '../../../../../classes/WebService';
import ConfigurationWebService from '../../../../../classes/ConfigurationWebService';
import FieldGenerator from '../../../../../components/FieldGenerator';

const { Option } = Select;

const CreateConfiguration = function (props) {

  const { onClose } = props;
  const { getFieldDecorator } = props.form;

  const [configTypes, setConfigTypes] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [configType, setConfigType] = useState('');

    
  const fetchConfigTypes = async ()=>{
     try {
       const webservice = new Webservice();
          webservice.get({endpoint:"configurations/types"})
          .then(res =>res.data)
          .then(configTypes => setConfigTypes(configTypes));
     } catch (error) {
       message.error("Failed to fetch configTypes");
     }
  }
  const cleanUp = ()=>{
      onClose();
      props.form.resetFields()
  }

  useEffect(()=>{
    fetchConfigTypes();
    return cleanUp;
  },[])

  const onSubmit =  (e) => {
    e.preventDefault();
    let service;
    props.form.validateFields( async (err, values) => {
      if (!err) {
      
        setLoading(true);
        values.mutable = true;
        service = new ConfigurationWebService();
         await service
         .save({data:values})
         .then(resp =>{
          props.refreshListPage();
          props.form.resetFields();
          onClose();
          message.success("Config variable added successfully");
         })
        .catch(err => {
          message.error(`Could not save. ${err.message} `);
        });

        setLoading(false);
       
      }
    })
  }

  const configTypeChanged= (val)=>{
    setConfigType(val)
  }

  return (
    <div>

        <Form layout="vertical"  >
        <div className="row">
          <div className="col-md-6">
          <Form.Item label="Label/Title">
                {getFieldDecorator('label', {
                  rules: [{ required: true, message: 'Please enter lable.' }],
                  // initialValue:"",
                })(<Input placeholder="Test Configuration" />)}
                </Form.Item>
          </div>
          <div className="col-md-6">
          <Form.Item label="Tag">
                {getFieldDecorator('tag', {
                  rules: [{ required: true, message: 'Please enter tag to group it' }],
                  // initialValue:"",
                })(<Input placeholder="tag" />)}
                </Form.Item>
          </div>
          <div className="col-md-6">
              <Form.Item label="Variable Type">
                {getFieldDecorator('type', {
                  rules: [{ required: true, message: 'Please select a type' }],
                  initialValue : configTypes[0]
                })(
                  <Select onChange={configTypeChanged} placeholder="Please select account type">
                    {configTypes.map((config_type) => <Option key={config_type.id} value={config_type}>{config_type.toUpperCase()}</Option>)}
                  </Select>,
                )}
              </Form.Item>
          </div>

        </div>
          <div className="row">
            <div className="col-md-6">
              <Form.Item label="Key">
                {getFieldDecorator('ref_id', {
                  rules: [{ required: true, message: 'Please enter key' }],
                  // initialValue:"",
                })(<Input placeholder="test_config" />)}
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item label="Initial Value">
                {getFieldDecorator('value', {
               
                })(
                 <FieldGenerator
                  type={configType}
                  placeholder="Just an initial value"
                 />)
                 }
                
              </Form.Item>
            </div>
                  
          <div className="col-md-6" hidden={['radio','select'].indexOf(configType) === -1}>
              <Form.Item label="Options">
                {getFieldDecorator('data.options', {
                  
                })(
                  <Input  placeholder="['yes','no'] or ['0','1'] or ['true','false']" />
                  )
                 }
              </Form.Item>
            </div>
          
          </div>  
          <h5>Validation Rules</h5>
          <div className="row">
          <div className="col-md-3">
              <Form.Item label="Required">
                {getFieldDecorator('data.validation.required', {
                  initialValue: false
               
                })(
                  <Switch initialValue={false}  />
                  )
                 }
                
              </Form.Item>
            </div>
          <div className="col-md-3">
              <Form.Item label="Confirmation">
                {getFieldDecorator('data.validation.confirmation', {
                  initialValue: false
                })(
                  <Switch initialValue={false}  />
                  )
                 }
              </Form.Item>
            </div>
          <div className="col-md-3">
              <Form.Item label="Message">
                {getFieldDecorator('data.validation.message', {
                  
                })(
                  <Input  placeholder="Validation error message" />
                  )
                 }
              </Form.Item>
            </div>
         
      
          </div>   
        </Form>
        <div
          style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
          }}
        >
          <Button onClick={onClose} style={{ marginRight: 8 }}>
            Cancel
            </Button>
          <Button disabled={isLoading}  onClick={onSubmit} type="primary">
        {isLoading ? <Icon type="loading" />  : "Submit"}
            </Button>
        </div>
    </div>
  )
}


export default Form.create()(CreateConfiguration);