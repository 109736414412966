import React, { useState, useContext, useEffect } from 'react';
import { Drawer, Form, Button, Col, Row, Input, Select, Divider, Icon, Upload, message, Switch, Alert } from 'antd';
import GlobalStateContext from '../../../../context'
import Webservice from '../../../../classes/WebService';
import StreamWebService from '../../../../classes/StreamWebService';
import TextArea from 'antd/lib/input/TextArea';




const { Option } = Select;

const EditClient = function (props) {
  const webservice  = new Webservice();

  const { getFieldDecorator } = props.form;
  const { item, setSelectedItem,onClose } = props;


  const [types, setTypes] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [client, setClient] = useState();
  const [clients, setClients] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [destination, setDestination] = useState({});

  const [state, dispatch] = useContext(GlobalStateContext);



  const fetchTypes = async () => {
    try {
      webservice.get({ endpoint: "streams/types" })
        .then(res => res.data)
        .then(types => setTypes(types));
    } catch (error) {
      message.error("Failed to fetch stream types");
    }
  }


  const fetchDestinations = async () => {
    try {
      const webservice = new Webservice();
      webservice.get({ endpoint: "streams/destinations" })
        .then(res => res.data)
        .then(dest => setDestinations(dest));
    } catch (error) {
      message.error("Failed to fetch destinations");
    }
  }

  const fetchClients = async () => {
    try {
      const webservice = new Webservice();
      webservice.get({ endpoint: "users/" })
        .then(res => res.data.entries)
        .then(clients => setClients(clients));
    } catch (error) {
      message.error("Failed to fetch types");
    }
  }

  const destinationChanged = (val) => {
    let selected;
    for (let i = 0; i < destinations.length; i++) {
      if (destinations[i].id === val) {
        selected = destinations[i];
      }
    }
    console.log(selected);
    setDestination(selected);
  }


  useEffect(() => {
    fetchTypes();
    fetchDestinations();
    fetchClients();
  }, [])


  const onUserChange = (val) => {
    console.log(val)
    setClient(val);
  }

  const onSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log(values)
        setLoading(true);
        const service = new StreamWebService();
        await service
          .save({ data: values, id: item.id })
          .then(resp => {
            setSelectedItem(false);
            props.refreshListPage();
            onClose();
            message.success("User info updated successfully");
          })
          .catch(err => {
            message.error(`Could not update item info. ${err.message} `);
          });

        setLoading(false);

      }
    })
  }


  return (
    <div>

      <Form layout="vertical" >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label={"Label"}>
              {getFieldDecorator('title', {
                rules: [{ required: true, min: 6, message: 'Label is required and must be at least 6 characters' }],
                initialValue: item.title,
                validateTrigger: 'onBlur'
              })(<Input placeholder="Firs Birthday Stream" />)}
            </Form.Item>
          </Col>

          <Col span={12}>

            <Form.Item label="Stream Type">
              {getFieldDecorator('type', {
                rules: [{ required: true, message: 'Please select type' }],
                initialValue: item.type,
                validateTrigger: 'onBlur'

              })(
                <Select placeholder="Please select type">
                  {types.map((type) => <Option key={type} value={type}>{type.toUpperCase()}</Option>)}
                </Select>,
              )}
            </Form.Item>
          </Col>
          <Col span={12}>

            <Form.Item label="Client ">
              {getFieldDecorator('user', {
                rules: [{ required: true, message: 'Please select client' }],
                validateTrigger: 'onBlur',
                initialValue: item.user ?  item.user._id : null
              })(
                <Select onChange={onUserChange} placeholder="Please select client">
                  {clients.map((client) => <Option key={client.id} value={client.id}>{client.name}</Option>)}
                </Select>,
              )}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Description">
              {getFieldDecorator('description', {
                rules: [{ message: 'Please enter description' }],
                initialValue : item.description
              })(<TextArea placeholder=" Streaming our first birthday party" />)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
 

      <div
        style={{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
        }}
      >
        <Button size='small' onClick={onClose} style={{ marginRight: 8 }}>
          Cancel
            </Button>
        <Button disabled={isLoading} type="button" onClick={onSubmit} type="primary">
          {isLoading ? <Icon type="loading" /> : "Update"}
        </Button>
      </div>
    </div>
  )
}


export default Form.create()(EditClient);