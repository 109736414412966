import React from 'react'
import axios from "axios";
import {message} from "antd";
import env from "../environments";
import { prepareURL } from "../utils";
import { Redirect } from "react-router-dom";

export default class Webservice {

  constructor() {
    this.headers = {
      "Content-Type": "application/json",
    }
    this.baseUrl = env.app.api.baseUrl;
    this.token = JSON.parse(localStorage.getItem('token'));
    if(this.token){
        this.headers["Authorization"] = `${this.token.tokenType} ${this.token.accessToken}`;
    }
  
  }



  async  post({ endpoint = "", data = {} }) {
    const { headers, baseUrl } = this;
    const url = prepareURL(`${baseUrl}${endpoint}`);
    try {
      return await axios.post(url, data, { headers })
        .then(res => res.data)
    } catch (error) {
      //check tokenexpiry
      // if(error.status == 401) this.post
      throw error
    }
  }


  async  get({ endpoint = "", data = {} },absolute=false ) {
    const { headers, baseUrl } = this;
    const url = absolute ? endpoint: prepareURL(`${baseUrl}${endpoint}`);
    try {
      return await axios.get(url, { params: data, headers })
        .then(res => res.data)
       
    } catch (error) {
      if(error.response !== undefined){
        const {response} = error
        if( response.status === 401){
          return <Redirect to="/login"/>
        }
        
        message.warning(`${response.data.message}`);
      }
      
      throw error
    }
  }

  async  delete({ endpoint = "", data = {} }) {
    const { headers, baseUrl } = this;
    const url = prepareURL(`${baseUrl}${endpoint}`);
    try {
      return await axios.delete(url, { headers })
        .then(res => res.data)
        
    } catch (error) {
      throw error
    }
  }
  async  put({ endpoint = "", data = {} }) {
    const { headers, baseUrl } = this;
    const url = prepareURL(`${baseUrl}${endpoint}`);
    try {
      return await axios.put(url,data, { headers })
        .then(res => res.data)
       
    } catch (error) {
      throw error
    }
  }
}