import reducer from './reducer';
import { useReducer } from 'react';
import {ACCOUNT_TYPE_PERSONAL,ACCOUNT_TYPE_BUSINESS} from '../constants'

const initialState = {
    image_data:"",
    showNewDataForm: false,
    user:false,
    authenticated: false,
    prediction:{},
    users:[],
    roles:[],
    package_frequencies:["day", "week", "month", "year"],
    userAccountTypes:[ACCOUNT_TYPE_PERSONAL,ACCOUNT_TYPE_BUSINESS],
    configuration:{},
    notifications:[]
    
};

const useGlobalState = () => {
    // console.log("Hook about to be called")
    return useReducer(reducer, initialState)
};


export default useGlobalState;
