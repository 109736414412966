import React from 'react'
import {  Tag, Divider, Col, Row, Timeline } from 'antd'
import MetaData from './MetaData';

export default function ViewTransaction(props) {
    const { detail, setViewDetail } = props;


    const colors = {
        'personal': 'blue',
        'business': 'green',
    }


    return (
        <div>
            <h4>{detail.title}</h4>
            <Row>
                <Col>
                    <p> <strong>TX #:</strong>  {detail.id}</p>
                    <p> <strong> Type:</strong> <Tag color={colors[detail.type]}>{detail.type} </Tag> </p>
                    <p> <strong>Client:</strong>  {detail.user ? detail.user.name : "N/A" }</p>
                    <p> <strong>Currency :</strong>  {detail.currency_code || "N/A"}</p>
                    <p> <strong>Exchange Rate :</strong>  {detail.xrate || "N/A"}</p>
                    <p> <strong>Amount(exp/act):</strong>  {detail.expected_amount} /  {detail.amount_paid}</p>
                    <p> <strong>Amount(USD)(exp/act):</strong>  {detail.expected_amount / (detail.xrate || 1) } /  {detail.amount_paid/ (detail.xrate || 1)}</p>
                    <p> <strong>Status:</strong>  {detail.status}</p>
                </Col>
                <Divider />
                <p> <strong>Created At </strong> : {(new Date(detail.createdAt)).toDateString()}</p>
                <Divider />
            </Row>

            <div>
                <Row gutter={16}>
                    <Col span={12}>
                        <h6>Status History</h6>
                        <Timeline>
                            {Array.isArray(detail.status_log) ? detail.status_log.reverse().map((item) => {
                                return <Timeline.Item> <Tag> {item.status}</Tag>
                                    @ {(new Date(item.time)).toDateString()}

                                </Timeline.Item>
                            })
                                : null}
                        </Timeline>


                    </Col>
                    <Col span={12}>
                        <h6>Meta</h6>
                        <MetaData type={detail.type} meta={detail.metadata} />
                    </Col>
                </Row>

            </div>
        </div>
    )
}
