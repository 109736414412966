import Webservice from './WebService';



export default class MessengerWebService extends Webservice {

    async send(payload = {}) {

        try {
            return await this.post({ endpoint: 'messenger/send', data: payload })
        } catch (error) {
            throw error;
        }
    }


}