import React from 'react'
import Webservice from './WebService';



export default class ClientWebService extends Webservice {

    async getUsers(data={}) {

        try {
            return await this.get({ endpoint: 'users', data})
        } catch (error) {
            throw error;
        }
    }
    async getAllUsers(data={}) {

        try {
            return await this.get({ endpoint: 'users/all', data})
        } catch (error) {
            throw error;
        }
    }

    /**
     * Saves Users info
     * @param {*} param0 
     */
    async save({data, userId}) {

        try {
            return userId ? 
            await this.put({ endpoint: `users/${userId}`, data }) : 
            await this.post({ endpoint: `users`, data })
        } catch (error) {
            throw error;
        }
    }
 

    async getUser(userId) {
        try {
            return  await this.get({ endpoint: `users/${userId}`}); 
        } catch (error) {
            throw error;
        }
    }
    async getSubscriptions(userId) {
        try {
            return  await this.get({ endpoint: `users/${userId}/subscriptions`}); 
        } catch (error) {
            throw error;
        }
    }
    async changeSubscription(userId,packageId) {
        try {
            return  await this.put({ endpoint: `users/${userId}/subscriptions`,data:{pkg:packageId}},); 
        } catch (error) {
            throw error;
        }
    }

    async deleteUser(userId) {
        try {
            return  await this.delete({ endpoint: `users/${userId}`}); 
        } catch (error) {
            throw error;
        }
    }

}