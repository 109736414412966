import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Divider, Tag, Drawer, Icon, Popconfirm, message } from "antd";
import GlobalStateContext from '../../../context';
import { FETCH_ITEMS } from '../../../store/actions/types'

import CreateStream from './components/CreateStream';
import ViewStream from './components/ViewStream';
import EditClient from './components/EditStream';
import CustomTable from '../../../components/CustomTable';
import StreamWebService from '../../../classes/StreamWebService';

let onViewButtonClick;
let onEditClick;
let onDeleteConfirm;
let onDeleteCancel;
let activeUser;

const renderRole = (role) => {
    return role ? <Tag > {role.name}</Tag> : null
}
const renderType = (actype) => {
    return <Tag > {actype}</Tag>
}

const renderStatus = (status) => {
    return <Tag > {['inactive','live'][status] }</Tag>
}
const columns = [
    {
        title: 'Title',
        key: 'title',
        dataIndex: 'title',
        render: text => text,
        ellipsis: true,
    },
    {
        title: 'Type',
        key: 'type',
        dataIndex: 'type',
        render: renderType,
    },
    {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        render: renderStatus,
    },

    {
        title: 'Destinations',
        dataIndex: 'destinations',
        key: 'destinations',
    render: dest => dest.length > 0 ? dest.map(d=> <Tag>{d.type}</Tag>) : "N/A",
    },
    {
        title: 'By',
        dataIndex: 'user',
        key: 'user',
        render: usr => usr ? usr.name  : null ,
        ellipsis: true,
    },
    {
        title: 'Actions',
        // fixed: 'right',
        render: (item) => (<div>
            <Button type="link" onClick={onViewButtonClick.bind(this, item)}>View</Button>
            <Divider type="vertical" />
            <Button type="link" onClick={onEditClick.bind(this, item)}>Edit</Button>
            <Divider type="vertical" />
            <Popconfirm
                title={`Are you sure delete ${item.title}?`}
                onConfirm={onDeleteConfirm.bind(this, item)}
                onCancel={onDeleteCancel}
                okText="Yes"
                cancelText="No"
            >
                <Button type="link">Delete</Button>
            </Popconfirm>
        </div>)
    }
]

function StreamIndexPage(props) {
    const [state, dispatch] = useContext(GlobalStateContext);
    const [isLoading, setIsLoading] = useState(false)
    const [viewDetail, setViewDetail] = useState(false);
    const [createFormVisibility, setCreateFormVisibility] = useState(false);
    const [editFormVisibility, setEditFormVisibility] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const [selectedItems, setSelectedItems] = useState([]);

    activeUser = state.user;

    const onClickNewButton = () => {
        setCreateFormVisibility(true)
    }

    const onCloseNewDrawer = () => {
        setCreateFormVisibility(false)
    }
    const onCloseEditDrawer = () => {
        setEditFormVisibility(false)
    }
    const closeViewDrawer = () => {
        setViewDetail(false);
    }
    onViewButtonClick = (item) => {
        setViewDetail(item);
    }
    onEditClick = (item) => {
        setSelectedItem(item);
        setEditFormVisibility(true)
    }

    onDeleteCancel = () => {

    }

    onDeleteConfirm = (item) => {
        try {
            setIsLoading(true);
            const service = new StreamWebService();
            service.deleteStream(item.id)
                .then(res => {
                    message.success(`${item.title} deleted successfully`);
                    fetchData()
                })
                .catch(err => {
                    message.error(`Failed to delete ${item.title}`);
                    console.log(err)
                }).finally(() => setIsLoading(true))
        } catch (error) {
            
            message.error(`Oops! We failed to delete ${item.title}`);
            console.log(error)
        }

    }

    const fetchData = async () => {
        setIsLoading(true);
        const service = new StreamWebService();
        const result = await service.getStreams();
        if (result.status == 200) {
            dispatch({
                type: FETCH_ITEMS,
                payload: ['streams', result.data.entries]
            })
        }
        setIsLoading(false);
    }

    useEffect(() => {
        // fetchData();      
    }, [])
    const buttonStyle = {
        position: "relative",
        float: "right",
        button: "50px",
        margin: "10px 10px"

    }

    return (
        <div>
            <div >
                <h5>
                    Streams
                    <Button style={buttonStyle} type='dashed' onClick={onClickNewButton}>
                        <Icon type="camera" /> New
                         </Button>
                </h5>
            </div>
            <div>
                {/* <Table rowKey={record => record.id} rowSelection={rowSelection}  columns={columns} dataSource={users} loading={isLoading} ></Table> */}
                <CustomTable
                    rowKey={record => record.id}
                    // rowSelection={rowSelection}  
                    columns={columns}
                    selected={selectedItems}
                    dataSource="/streams/"
                    loading={isLoading}
                    stateSubjectKey='streams'
                />

            </div>
            <Drawer
                title="Create Stream"
                width={720}
                bodyStyle={{ paddingBottom: 80 }}
                visible={createFormVisibility}
                onClose={onCloseNewDrawer}  >
                <CreateStream onClose={onCloseNewDrawer} refreshListPage={fetchData} />
            </Drawer>
            <Drawer
                title={`Update ${selectedItem ? selectedItem.name : ''} detail`}
                width={720}
                onClose={onCloseEditDrawer}
                visible={editFormVisibility}
                bodyStyle={{ paddingBottom: 80 }}
            >
                {selectedItem ?
                <EditClient onClose={onCloseEditDrawer} refreshListPage={fetchData} item={selectedItem} setSelectedItem={setSelectedItem} />
                : null}
            </Drawer>

            <Drawer visible={viewDetail ? true : false} onClose={closeViewDrawer} width={'40vw'} >
            {viewDetail ? <ViewStream detail={viewDetail} setViewDetail={setViewDetail} /> : null}
            </Drawer>

        </div>


    )
}

export default withRouter(StreamIndexPage)
