import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,Label } from 'recharts';

const data = [
    {
        name: 'Page A',
        uv: 4000,
        pv: 2400,
        amt: 2400,
    },
    {
        name: 'Page B',
        uv: 3000,
        pv: 1398,
        amt: 2210,
    },
    {
        name: 'Page C',
        uv: 2000,
        pv: 9800,
        amt: 2290,
    },
    {
        name: 'Page D',
        uv: 2780,
        pv: 3908,
        amt: 2000,
    },
    {
        name: 'Page E',
        uv: 1890,
        pv: 4800,
        amt: 2181,
    },
    {
        name: 'Page F',
        uv: 2390,
        pv: 3800,
        amt: 2500,
    },
    {
        name: 'Page G',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
];

export default class Example extends PureComponent {
    static demoUrl = 'https://codesandbox.io/s/simple-line-chart-kec3v';
    // constructor(props){
    //     this.setState({
    //         data:props.data
    //     })
    // }
    render() {
        const {x,y,data,label} = this.props
        console.log(this.props);
        return (
            <ResponsiveContainer   aspect width="100%" height="100%">
                <LineChart 
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                    <XAxis dataKey={x.key}><Label name={x.label}  /></XAxis>
                    <YAxis dataKey={y.key}><Label name={y.label} /> </YAxis>
                    <Tooltip />
                    <Line type="monotone" dataKey={y.key} stroke="#82ca9d" />
                </LineChart>
            </ResponsiveContainer>
        );
    }
}
