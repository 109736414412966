
import Webservice from './WebService';



export default class AuthService extends Webservice {

    async login({ email, password }) {

        try {
            return await this.post({ endpoint: 'auth/login', data: { email, password } })
        } catch (error) {
            throw error;
        }
    }


    async logout() {
        try {

        } catch (error) {
            throw error;
        }
    }

    async getUserProfile(){
         try{
             return  await this.get({endpoint:'auth/profile',})
         }catch(error){
            throw error;
         }
    }

}