import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import GlobalStateContext from '../context';



export default function ProtectedRoute({ children, ...rest }) {
    const [state, dispatch] = useContext(GlobalStateContext)

   
    return (

        <Route
            {...rest}
            render={({ location }) => {
               
                return state.user  ?
                    (children)
                    :
                    (<Redirect
                        to={
                            {
                                pathname: "/login",
                                state: { from: location }
                            }
                        }
                    />);
            }}
        >
        </Route>
    )
}
