export default {
    app: {
        name: "Streaming Horse",
        version: '0.0.1',
        api: {
            baseUrl: "https://api.streaminghorse.com/v1/admin/",
            socket : "https://api.streaminghorse.com/",
            logger : "https://webhook.site/89cc27b4-4385-4224-a5d8-a6f91d8236dd",
            streamingServer : {
                http: "https://streaminghorse.com:5004/",
                https: "https://streaminghorse.com:8443/",
                websocket: "ws://streaminghorse.com:5004/",
                rtmp: "rtmp://streaminghorse.com:1935/",
                apps:{
                    live:'live'
                }
            },
        }

    }

}