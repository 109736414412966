import React, { useContext, useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Button, Table, Divider, Tag, Drawer, Icon, Popconfirm, message } from "antd";
import NewData from '../../../../components/admin/NewData';
import GlobalStateContext from '../../../../context';
import { SHOW_NEW_DATA_FORM,SHOW_VIEW_ADMIN_FORM, FETCH_ITEMS, SET_ACTIVE_USER } from '../../../../store/actions/types'

import ViewRole from './components/ViewRole';
import EditRole from './components/EditRole';
import environments from '../../../../environments';
import CustomTable from '../../../../components/CustomTable';
import CreateRole from './components/CreateRole';
import RoleWebService from '../../../../classes/RoleWebService';




let onViewButtonClick;
let onEditClick;
let onDeleteConfirm;
let onDeleteCancel;
let activeUser;

const renderPerms = (items) => {

    return items.map(item => <Tag key={item.ref_id} > {item.name}</Tag>) 
}
const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        
    },
    {
    
        title: 'System Name',
        dataIndex: 'name',
        render: text => text,
        ellipsis: true,
    },

    {
        title: 'Permissions',
        dataIndex: 'permissions',
        key: 'permissions',
        render: renderPerms,
        ellipsis: true,
    },
    {
        title: 'Actions',
        // fixed: 'right',
        render: (item) => (<div>
            <Button type="link" onClick={onViewButtonClick.bind(this,item)}>View</Button>
            <Divider type="vertical" />
            <Button type="link" onClick={onEditClick.bind(this,item)}>Edit</Button>
            <Divider type="vertical" />
            <Popconfirm disabled={item.ref_id == 'super_admin'}
             title={`Are you sure delete ${item.name}?`}
             onConfirm={onDeleteConfirm.bind(this,item)}
             onCancel={onDeleteCancel}
             okText="Yes"
             cancelText="No"
             
            >

            <Button disabled={item.ref_id == 'super_admin'} type="link">Delete</Button>
            </Popconfirm>
        </div>)
    }
]

 function RoleIndexPage(props) {
    const roleWebService = new RoleWebService()
    const [state, dispatch] = useContext(GlobalStateContext);
    const [isLoading,setIsLoading] = useState(false)
    const [selectedItem,setSelectedItem] = useState();
    const [selectedItems,setSelectedItems] = useState([]);
    const [showCreateForm,setShowCreateForm] = useState(false);
    const [showViewDrawer,setShowViewDrawer] = useState(false);
    const [showEditDrawer,setShowEditDrawer] = useState(false);

    activeUser = state.user;
    const {users} = state;
 
    const onClickNewButton = () => {
        setShowCreateForm(true);
    }

     onViewButtonClick = (item) => {
        setSelectedItem(item);
        setShowViewDrawer(true);
    }
     onEditClick = (item) => {
        setSelectedItem(item);
        setShowEditDrawer(true);
    }

    onDeleteCancel = ()=>{
            
    }

    onDeleteConfirm = (item)=>{
        setIsLoading(true);
        roleWebService.deleteRole(item.id)
        .then(res=>{
            message.success(`${item.name} deleted successfully`);
            fetchData()
        })
        .catch(err=> {
            message.error(`Failed to delete ${item.name}`);
        }).finally(()=> setIsLoading(true))
    }

    const fetchData = async ()=>{
        setIsLoading(true);
        console.log("@here")
        const result = await roleWebService.getRoles();
        if(result.status ==  200){
            dispatch({
                type: FETCH_ITEMS,
                payload: ['roles',result.data.entries]
            })
        }
        setIsLoading(false);
    }

    useEffect(()=>{
        fetchData();      
    },[])

    const buttonStyle = {
        position: "relative",
        float: "right",
        button: "50px",
        margin: "10px 10px"

    }

    return (
        <div>
            <div >
                <h5>
                     Roles
                    <Button style={buttonStyle} type='dashed' onClick={onClickNewButton}>
                         <Icon type="user-add"/> New 
                         </Button>
                </h5>
            </div>
            <div>
                <CustomTable 
                rowKey={record => record.ref_id} 
                // rowSelection={rowSelection}  
                columns={columns} 
                selected = {selectedItems}
                dataSource="/roles/" 
                loading={isLoading}
                stateSubjectKey='roles'
                />
            
            </div>
            <Drawer visible={showCreateForm} onClose={()=>setShowCreateForm(false)} width={'40vw'} >
                <CreateRole onClose={setShowCreateForm} refreshListPage={fetchData}/> 
            </Drawer>
            <Drawer visible={showEditDrawer} onClose={()=>setShowEditDrawer(false)} width={'40vw'} >
                <EditRole  refreshListPage={fetchData} onClose={()=>setShowEditDrawer(false)} item={selectedItem}/>
            </Drawer>
            
           
            <Drawer visible={showViewDrawer} onClose={()=>setShowViewDrawer(false)} width={'40vw'} >
                <ViewRole item={selectedItem} title="Role" />
            </Drawer>

        </div>


    )
}

export default withRouter(RoleIndexPage)
