import Webservice from './WebService';



export default class TransactionWebService extends Webservice {

    /**
     * Request for transactions
     * @param {object} conditions 
     */
    async getTransactions(conditions={}) {

        try {
            return await this.get({ endpoint: 'transactions', data: conditions })
        } catch (error) {
            throw error;
        }
    }

    /**
     * Saves Users info
     * @param {*} param0 
     */
    async save({data, id}) {

        try {
            return id ? 
            await this.put({ endpoint: `transactions/${id}`, data }) : 
            await this.post({ endpoint: `transactions`, data })
        } catch (error) {
            throw error;
        }
    }
 

    async getTransaction(id) {
        try {
            return  await this.get({ endpoint: `transactions/${id}`}); 
        } catch (error) {
            throw error;
        }
    }


    async deleteTransaction(id) {
        try {
            return  await this.delete({ endpoint: `transactions/${id}`}); 
        } catch (error) {
            throw error;
        }
    }

}