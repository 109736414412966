import React, { useState, useContext, useEffect } from 'react';
import { Drawer, Form, Button, Col, Row, Input, Select, Divider, Icon, Upload, message, Switch, InputNumber } from 'antd';
import GlobalStateContext from '../../../../context'
import { SHOW_NEW_DATA_FORM, SAVE_USER } from '../../../../store/actions/types';
import Webservice from '../../../../classes/WebService';
import { sanitizePhoneNumber, sanitizeLabel } from '../../../../utils';
import PackageWebService from '../../../../classes/PackageWebService';




const { Option } = Select;

const EditPackage = function (props) {
  const webservice = new Webservice();
  const { getFieldDecorator } = props.form;
  const { item, onClose } = props;


  const [accountTypes, setAccountTypes] = useState([]);
  const [features, setFeatureTypes] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [state, dispatch] = useContext(GlobalStateContext);



  const fetchAccountTypes = async () => {
    try {
      webservice.get({ endpoint: "users/account_types" })
        .then(res => res.data)
        .then(accountTypes => setAccountTypes(accountTypes));
    } catch (error) {
      message.error("Failed to fetch account types");
    }
  }
  const fetchFeaturesTypes = async () => {
    try {
      const webservice = new PackageWebService();
      webservice.getFeatureTypes()
        .then(res => res.data)
        .then(features => setFeatureTypes(features));
    } catch (error) {
      message.error("Failed to fetch features");
    }
  }

  const cleanup = () => {
    console.log("Cleaning up",item)
    setFeatureTypes([]);
    setAccountTypes([]);
  }

  useEffect(() => {
    fetchAccountTypes();
    fetchFeaturesTypes();
    return cleanup
  }, [item])


  const onSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        values.phone = sanitizePhoneNumber(values.phone);
        values.status = values.status === true ? '1' : '0';
        values.is_free = values.is_free === true ? '1' : '0';
        values.visibility = values.visibility === true ? '1' : '0';

        setLoading(true);
        const service = new PackageWebService();
        await service
          .save({ data: values, id: item.id })
          .then(resp => {
            onClose()
            props.refreshListPage();
            message.success("Package info updated successfully");
          })
          .catch(err => {
            message.error(`Could not update package info. ${err.message} `);
          });

        setLoading(false);

      }
    })
  }


  const featureSection = () => {

    return <div className="col col-sm-12">

      <table className="table">
        <thead>
          <tr>
            <th>
              Feature
            </th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>

          {Object.keys(features).map((ft, i) => {
            let input = <></>;
            let isSwitch = false;
            if (features[ft].type === "Number") {
              input = <InputNumber min="-1" />
            }

            if (features[ft].type === "Boolean") {
              isSwitch = true;
              input = <Switch
                checkedChildren={<Icon type="check" />}
                uncheckedChildren={<Icon type="close" />}
              />
            }

            return (
              <tr className="">
                <td> {features[ft].label ? features[ft].label : sanitizeLabel(ft).toUpperCase()} </td>
                <td>
                  <Form.Item>
                    {
                      getFieldDecorator(`features[${ft}]`, {
                        rules: [{ required: true, message: ` ${ft} is required ` }],
                        initialValue: item.features ? item.features[ft] : null,
                        [isSwitch ? 'valuePropName' : '']: item.features ? item.features[ft] ? "checked" : "unchecked" : "unchecked"
                      })(input)}
                  </Form.Item>
                </td>
              </tr>)

          })}

        </tbody>
      </table>

    </div>
  }


  return (

    <div>
      <Form layout="vertical" >
        <h5> General Information </h5>

        <div className="row">
          <div className="col col-md-6 col-lg-3 col-sm-12">
            <Form.Item label="Name">
              {getFieldDecorator('name', {
                rules: [{ required: true, message: 'Please enter package\'s name' }],
                initialValue: item.name
              })(<Input placeholder="Please enter package name" />)}
            </Form.Item>
          </div>

          <div className="col col-md-6 col-lg-3 col-sm-12">

            <Form.Item label="Price($)">
              {getFieldDecorator('price', {
                rules: [{ required: true, message: 'Please enter package\'s price' }],
                initialValue: item.price
              })(<InputNumber type="number" min={0.00} placeholder="Please enter package price" />)}
            </Form.Item>
          </div>
          <div className="col col-md-6 col-lg-3 col-sm-12">
            <Form.Item label="Duration">
              {getFieldDecorator('duration', {
                rules: [{ required: true, message: 'Please enter package\'s duration' }],
                initialValue: item.duration
              })(<InputNumber min={1} placeholder="Please enter package duration" />)}
            </Form.Item>
          </div>
          <div className="col col-md-6 col-lg-3 col-sm-12">
            <Form.Item label="Frequency">
              {getFieldDecorator('frequency', {
                rules: [{ required: true, message: 'Please select frequency' }],
                initialValue: item.frequency
              })(
                <Select placeholder="Please select frequency">
                  {state.package_frequencies.map((fq) => <Option key={fq} value={fq}>{fq}</Option>)}
                </Select>,
              )}
            </Form.Item>

          </div>
          <div className="col col-md-6 col-lg-3 col-sm-12">
            <Form.Item label="Account Type">
              {getFieldDecorator('account_type', {
                rules: [{ required: true, message: 'Please select type' }],
                initialValue: item.type

              })(
                <Select placeholder="Please select type">
                  {accountTypes.map((accType) => <Option key={accType} value={accType}>{accType}</Option>)}
                </Select>,
              )}
            </Form.Item>
          </div>

          {featureSection()}



          <div className="col col-md-6 col-sm-12">
            <Form.Item label="Visibility">
              {getFieldDecorator('visibility', {
                rules: [{ required: true, message: 'Please choose the status' }],
                initialValue: item.visibility === '1' ? true : false,
              })(
                <Switch
                  checkedChildren={<Icon type="check" />}
                  uncheckedChildren={<Icon type="close" />}
                  defaultChecked={item.visibility === '1' ? true : false}
                />
              )}
            </Form.Item>
          </div>
          <div className="col col-md-6 col-lg-3 col-sm-12">
            <Form.Item label="Status">
              {getFieldDecorator('status', {
                rules: [{ required: true, message: 'Please choose the status' }],
                initialValue: item.status === '1' ? true : false,
              
              }
              )(
                <Switch
                defaultChecked = {item.status === '1' ? true : false}
                  checkedChildren={<Icon type="check" />}
                  uncheckedChildren={<Icon type="close" />}
                 
                />
              )}
            </Form.Item>
          </div>
          {/* <Col span={12}>
              <Form.Item label="Is Free">
                {getFieldDecorator('is_free', {
                  initialValue : item.is_free === '1' ? true :false
                })(
                  <Switch 
                  checkedChildren={<Icon type="check"/>}
                  uncheckedChildren={<Icon type="close"/>}
                  defaultChecked={ item.is_free === '1' ? true : false }
                  />
                )}
              </Form.Item>
            </Col> */}
        </div>

      </Form>
      <div
        style={{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
        }}
      >
        <Button size='small' onClick={onClose} style={{ marginRight: 8 }}>
          Cancel
            </Button>
        <Button disabled={isLoading} type="button" onClick={onSubmit} type="primary">
          {isLoading ? <Icon type="loading" /> : "Update"}
        </Button>
      </div>
    </div>
  )
}


export default Form.create()(EditPackage);