import { Form, Icon, Input, Button, Checkbox, Card, Divider, message, Alert, Spin } from 'antd';
import React, { useContext,useState } from "react";
import GlobalStateContext from '../context';
import { LOGIN } from '../store/actions/types';
import AuthService from '../classes/AuthService';


const LoginForm = (props) => {
    const { getFieldDecorator } = props.form;
    const [state, dispatch] = useContext(GlobalStateContext);
    const [loginError, setLoginError] = useState(0);
    const [isLoading, setIsLoading] = useState(0);

    const handleSubmit =  e => {
        e.preventDefault();
        props.form.validateFields( async (err, values) => {
            setLoginError(0);
            setIsLoading(1);

            if (!err) {
                

                try {
                    const authWbs = new AuthService();
                    const result = await authWbs.login(values);
                    setIsLoading(0);
                    if(result.status != 200){
                        return setLoginError(result.message);
                    }
                    
                
                    dispatch({
                        type: LOGIN,
                        payload: result.data
                    });

                }catch(error){
                    message.error("Login Failed");
                    setLoginError(error.message);
                   
                }


            }

            setIsLoading(0);

        });
    };





    const style = {
        position: "relative",
        margin: '1.5em auto',
        maxWidth: '500px',
    }

    const submitButtonStyle = {
        margin: 'auto',
        position: 'relative'
    }

    return (
        <Card title="Login" className="login_form" style={style}>
            
            <Form  onSubmit={handleSubmit} className="login-form">
                
                <Form.Item>
                    {getFieldDecorator('email', {
                        rules: [{ required: true, message: 'Please input your email!' }],
                    })(
                        <Input type="email"
                            prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            placeholder="email"
                        />,
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('password', {
                        rules: [{ required: true, message: 'Please input your Password!' }],
                    })(
                        <Input
                            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            type="password"
                            placeholder="Password"
                        />,
                    )}
                </Form.Item>
                <Form.Item>

                    <Button 
                    disabled={isLoading}
                    style={submitButtonStyle} type="primary" htmlType="submit" className="login-form-button">
                        Log in {isLoading ? <Spin indicator={<Icon type="loading"/>}/> : null}
                    </Button>
                    <Divider />
                    {getFieldDecorator('remember', {
                        valuePropName: 'checked',
                        initialValue: true,
                    })(<Checkbox>Remember me</Checkbox>)}
                    <br/>
                    <a className="login-form-forgot" href="">
                        Forgot password
          </a>
          <br/>
          { loginError ? 
                <Alert type="error" message ={loginError}/> : null } 
                </Form.Item>
            </Form>

        </Card>

    );
}



export default Form.create({ name: 'normal_login' })(LoginForm);