import React from 'react'

import 'antd/dist/antd.css';

import {Switch,Route } from "react-router-dom";
import { clientRoutes } from "../../routers/routes";

export default function Home() {
    const buildRoutes = (item,index) => {
        return  !item.children ? (
        <Route exact={item.exact} key={index} path={item.path}>
          {  item.component}
          </Route>) :
          item.children.map(buildRoutes);
        }
        return (
            <Switch>
                {clientRoutes.map(buildRoutes)}
            </Switch>
        )
}
