import React, { useState, useEffect } from 'react'
import { Drawer, Tag, Divider, Col, Row, Skeleton, Timeline, Empty, message } from 'antd'
import Webservice from '../../../../classes/WebService';
import PackageWebService from '../../../../classes/PackageWebService';

export default function ViewPackage(props) {
    const { detail } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [features, setFeatureTypes] = useState([]);
    const colors = {
        'personal': 'blue',
        'business': 'green',
    }

    const fetchFeaturesTypes = async () => {
        try {
            const webservice = new PackageWebService();
            webservice.getFeatureTypes()
                .then(res => res.data)
                .then(features => setFeatureTypes(features));
        } catch (error) {
            message.error("Failed to fetch features");
        }
    }


    const renderFeatures = (feature) => {
        const _ft = detail.features[feature];

        var _value = _ft ? _ft.toString() : "N/A";
        const _label = features[feature] || " ";

        _value = _value === "-1" ? "unlimited" : _value === "true" ? "yes" : _value;

        return (
            <tr color="green">
                <td> {_label.label}</td>
                <td> {_value}</td>
            </tr>)

    }

    useEffect(() => {
        console.log(detail)
        fetchFeaturesTypes()
        return () => {
            setFeatureTypes([]);
        }
    }, [detail])
    return (

        <div>
            <h4>{detail.name}</h4>
            <Row>
                <Col>
                    <p> <strong>code: </strong> {detail.ref_id}</p>
                    <p> <strong>Frequency: </strong> {detail.frequency}</p>
                    <p> <strong>Price:</strong>  $ {detail.price} </p>
                    <p> <strong>Type:</strong>  {detail.type}</p>
                </Col>

            </Row>

            <div>
                <h6>Features</h6>
                <table className="table">
                    <tr>
                        <th>Feature</th>
                        <th>Value</th>
                    </tr>
                    {Object.keys(features).map(renderFeatures)}
                </table>
            </div>
        </div>
    );
}
