import React, { useContext } from 'react'
import Hero from '../../components/front/Hero'
import 'antd/dist/antd.css';
import GlobalStateContext from '../../context';


export default function Landing() {
    const [state, dispatch] = useContext(GlobalStateContext);

    return (
        <React.Fragment>
            <Hero/>
        </React.Fragment>
    )
}
