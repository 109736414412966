
import React, {useContext } from 'react';
import GlobalStateContext from "../../context";
import {Skeleton, Button } from "antd"
import env from "../../environments";
import '../../App.css';
import { BrowserRouter , Redirect,withRouter} from "react-router-dom";

const buttonStyle= {
  marginTop:"100px"

}
const welcomeMessage = env.app.name


function Hero(props) {

  const toLoginPage = ()=>{

    console.log("Hello ");
      return props.history.push("/admin");
  }
  const [state, dispatch] = useContext(GlobalStateContext)

  return (

    <header className="App-header">
      <h1 >
        {welcomeMessage}
      </h1>
      {/* <div className="hero-previews">
        <div className="hero-preview">
          <div className="hero-preview-content">
          <Skeleton avatar={{shape:"square",size:180}} ></Skeleton>
          </div>
        </div>
      </div> */}
      <div className="try">
         <Button onClick={toLoginPage} className="btn-success" size="large" style={buttonStyle}> Dashboard </Button>
      </div>
    </header>
  )
}


export default withRouter(Hero)