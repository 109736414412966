import React, { useState, useContext, useEffect } from 'react';
import {  Form, Button, Col, Row, Input, Select, Icon, message, Switch } from 'antd';
import GlobalStateContext from '../../../../../context'
import {  SAVE_ITEM } from '../../../../../store/actions/types';
import Webservice from '../../../../../classes/WebService';
import {snakecase} from '../../../../../utils';
import RoleWebService from '../../../../../classes/RoleWebService';

const { Option } = Select;


const CreateRole = function (props) {
  const webservice = new Webservice();
const roleWebService = new RoleWebService();

  const { getFieldDecorator } = props.form;
  const { onClose } = props;
  const [refId, setRefId] = useState('');
  const [perms, setPerms] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [state, dispatch] = useContext(GlobalStateContext);
  
  
  const fetch = async ()=>{
     try {
          webservice.get({endpoint:"permissions/"})
          .then(res =>res.data.entries)
          .then(items => setPerms(items));
     } catch (error) {
       message.error("Failed to fetch perms");
     }
  }

  useEffect(()=>{
    fetch();
  },[])



  const onSubmit =  (e) => {
    e.preventDefault();
    props.form.validateFields( async (err, values) => {
      console.log(values)
      if (!err) {
        values.status = values.status == true ? '1': '0';
        setLoading(true);
         await roleWebService
         .save({data:values})
         .then(resp =>{
          dispatch({
            type: SAVE_ITEM,
            payload:['roles' , resp.data]
          });
          onClose();
          props.refreshListPage();
          message.success("Role saved successfully");
         })
        .catch(err => {
          message.error(`Could not save. ${err.message} `);
        });

        setLoading(false);
       
      }
    })
  }


  const nameOnChange = (e) => {
    setRefId(snakecase(e.target.value));
  }

  return (
    <div>

        <Form layout="vertical" >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Name">
                {getFieldDecorator('name', {
                  rules: [{ required: true, message: 'Please enter  name' }],
                })(<Input placeholder="Please enter  name" onChange={nameOnChange} />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Reference ID">
                {getFieldDecorator('re_id', {
                  initialValue: refId
                })(<Input disabled placeholder="role_name" />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Permissions">
                {getFieldDecorator('permissions', {
                  rules: [{ required: true, message: 'Please select a permissions' }],
                })(
                  <Select placeholder="Please select permissions" mode="multiple">
                    {perms.map((role) => <Option key={role.id} value={role.id}>{role.name}</Option>)}
                  </Select>,
                )}
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Status">
                {getFieldDecorator('status', {
                  rules: [{ required: true, message: 'Please choose the status' }],
                  initialValue : true
                })(
                  <Switch defaultChecked={true}  onChange={(val)=> console.log(val)}  />
                )}
              </Form.Item>
            </Col>

          </Row>     
        </Form>
        <div
          style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
          }}
        >
          <Button onClick={onClose} style={{ marginRight: 8 }}>
            Cancel
            </Button>
          <Button disabled={isLoading} type="button" onClick={onSubmit} type="primary">
        {isLoading ? <Icon type="loading" />  : "Submit"}
            </Button>
        </div>

    </div>
  )
}


export default Form.create()(CreateRole);