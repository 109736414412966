export default {
    app: {
        name: "StreamingHorse",
        version: '0.0.1',
        api: {
            baseUrl: "http://localhost:3000/v1/admin/",
            socket : "http://localhost:3000/",
            logger : "https://webhook.site/89cc27b4-4385-4224-a5d8-a6f91d8236dd",
            streamingServer : {
                http: "http://localhost:8000/",
                https: "https://localhost:8443/",
                websocket: "ws://localhost:8000/",
                rtmp: "rtmp://localhost:1935/",
                apps:{
                    live:'live'
                }
            },

    }

},
flutterwave :{
    production:false,
    keys:{
        public :"FLWPUBK_TEST-49e7836909ccf5e738445a5a2c1866ae-X" 
    }
}
}