import React, { useContext, useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Button, Table, Divider, Tag, Drawer, Icon, Popconfirm, message, Card, Col, Row } from "antd";
import GlobalStateContext from '../../../context';
import CreatePackage from './components/CreatePackage';
import ViewPackage from './components/ViewPackage';
import EditPackage from './components/EditPackage';
import environments from '../../../environments';
import CustomTable from '../../../components/CustomTable';
import PackageWebService from '../../../classes/PackageWebService';
import Meta from 'antd/lib/card/Meta';
import {abbreviator} from '../../../utils';

let onViewButtonClick;
let onEditClick;
let onDeleteConfirm;
let onDeleteCancel;
let activePackage;


const renderAccountType = (actype) => {
    return <Tag > {actype}</Tag>
}



function PackageIndexPage(props) {
    const [state, dispatch] = useContext(GlobalStateContext);
    const [isLoading, setIsLoading] = useState(false)
    const [viewDetail, setViewDetail] = useState(false);
    const [createFormVisibility, setCreateFormVisibility] = useState(false);
    const [editFormVisibility, setEditFormVisibility] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState({});
    const [packages, setPackages] = useState([]);


    const onClickNewButton = () => {
        setCreateFormVisibility(true)
    }

    const onCloseNewDrawer = () => {
        setCreateFormVisibility(false)
    }
    const onCloseEditDrawer = () => {
        setEditFormVisibility(false);
        setViewDetail(false);
        setSelectedPackage({})
    }
    const closeViewDrawer = () => {
        setViewDetail(false);
        setSelectedPackage({})
    }
    onViewButtonClick = (pkg) => {
        setViewDetail(true);
        setSelectedPackage(pkg);
    }

    onEditClick = (pkg) => {
        setSelectedPackage(pkg);
        setEditFormVisibility(pkg)
    }

    onDeleteCancel = () => {

    }

    onDeleteConfirm = (pkg) => {
        setIsLoading(true);
        const service = new PackageWebService();
        service.deletePackage(pkg.id)
            .then(res => {
                message.success(`${pkg.name} deleted successfully`);
                fetchData()
            })
            .catch(err => {
                message.error(`Failed to delete ${pkg.name}`);
                console.log(err)
            }).finally(() => setIsLoading(true))
    }

    const fetchData = async () => {
        setIsLoading(true);
        const service = new PackageWebService();
        const result = await service.getPackages();
        if (result.status === 200) {
            setPackages(result.data.entries)
        }
        setIsLoading(false);
    }
    const loaders = () => {
        return (
            <div className="package-cards">
                <Card className="package-card" loading={isLoading} />
                <Card className="package-card" loading={isLoading} />
                <Card className="package-card" loading={isLoading} />
                <Card className="package-card" loading={isLoading} />
            </div>
        )

    }
    const entries = () => {
        return <div className="row package-cards full-width" >
            {
                packages.map((pkg) => {
                    return (

                        <Card key={pkg.id}
                            className="package-card" hoverable loading={isLoading}
                            actions={[
                                <Icon type="eye" onClick={onViewButtonClick.bind(this, pkg)} key="setting" />,
                                <Icon type="edit" onClick={onEditClick.bind(this, pkg)} key="edit" />,
                                <Popconfirm 
                                    title={`Are you sure delete ${pkg.name}?`}
                                    onConfirm={onDeleteConfirm.bind(this, pkg)}
                                    onCancel={onDeleteCancel}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Icon type="delete"  />
                                </Popconfirm>
                            ]}
                        >

                            <div style={{ textAlign: "center" }}>
                                {<h5>{pkg.name}</h5>}
                            </div>

                            <div>
                                <div className="package-card-details">
                                    <li>
                                        {pkg.duration} <br />
                                        <small>duration</small>
                                    </li>
                                    <li>
                                        {pkg.frequency} <br />
                                        <small>frequency</small>
                                    </li>
                                    <li>
                                        {pkg.price} <br />
                                        <small>price</small>
                                    </li>

                                </div>
                            </div>



                        </Card>
                    )
                }
                )}
        </div>



    }
    useEffect(() => {
        fetchData();
    }, [])
    const buttonStyle = {
        position: "relative",
        float: "right",
        button: "50px",
        margin: "10px 10px"

    }

    return (
        <div>
            <div >
                <h5>
                    Packages
                    <Button style={buttonStyle} type='dashed' onClick={onClickNewButton}>
                        <Icon type="code-sandbox" /> Create Package
                    </Button>
                </h5>
            </div>
            <div>
                {isLoading ? loaders() : entries()}
            </div>
            <Drawer
                title="Add Package"
                width={720}
                bodyStyle={{ paddingBottom: 80 }}
                visible={createFormVisibility}
                onClose={onCloseNewDrawer}  >
                <CreatePackage onClose={onCloseNewDrawer} refreshListPage={fetchData} />
            </Drawer>
            <Drawer
                title={`Update ${selectedPackage ? selectedPackage.name : ''} detail`}
                width={720}
                onClose={onCloseEditDrawer}
                visible={editFormVisibility}
                bodyStyle={{ paddingBottom: 80 }}
            >
                {
                    editFormVisibility &&(

                        <EditPackage onClose={onCloseEditDrawer} refreshListPage={fetchData} item={selectedPackage} setSelectedPackage={setSelectedPackage} />
                    )
                }
            </Drawer>

            <Drawer visible={viewDetail} onClose={closeViewDrawer} width={'20vw'} >
            {viewDetail  &&  <ViewPackage detail={selectedPackage} /> }
            </Drawer>

        </div>


    )
}




export default withRouter(PackageIndexPage)
