import React,{useContext, useState,useEffect}from 'react'

import { Popover, Icon } from 'antd';
import StreamWebService from '../../../../classes/StreamWebService';
import GlobalStateContext from '../../../../context';


export default function DestinationItem(props) {
    const [state,dispatch] =  useContext(GlobalStateContext)
    const {stream_destinations} = state;
    const [itemMeta,setItemMeta] = useState({});

    const webservice = new StreamWebService();
    const { item,stream } = props;
    const deleteDestination = (type)=>{
        return webservice.removeDestination(stream.id,type);
    }

    const popoverContent = (
        
        <>
        <Icon onClick={deleteDestination.bind(this,item.type)} type="delete"></Icon>
        </>
    
    )

    const getMeta = ()=>{
        for(var i=0; i < stream_destinations.length; i++){
            const strd = stream_destinations[i]
            if(item.type == strd.id){
                setItemMeta(strd);
                break;
            }
        }
    };


    useEffect(() => {
        getMeta();
    }, [stream_destinations])
    return (
        <Popover content={popoverContent}>
            <li class="media">
                <img src={itemMeta.logo} class="mr-3" width="50" alt={item.type} />
            </li>
        </Popover>
     

    )
}
