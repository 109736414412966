import  React,{useState,useEffect} from "react";
import { Form, Input, InputNumber, Switch } from "antd";
import PropTypes from "prop-types";
import TextArea from "antd/lib/input/TextArea";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

/**
 * 
 * @param {{type: String , onChange : Function , name : String , defaultValue: String , label: String , placeholder:String}} props 
 */
const  FieldGenerator  =  (props) =>{
    const { type, onChange, name, defaultValue, label, placeholder } = props;
    const [formElement, setFormElement] = useState();
    const elemType = type;


    const  getFormElem = ()=>{
        console.log("Genrating  form element",elemType)
        let element ;
        switch (elemType.toLowerCase()) {
            case 'text':
                element = (<Form.Item  key={name} label={label}>
                    <Input name={name} defaultValue={defaultValue}
                        onChange={onChange}
                        placeholder={placeholder || `Please enter item ${label}`}
                    />
                </Form.Item>)
                break;
            case 'text_area':
                element = (<Form.Item  key={name} label={label}>
                    <Editor name={name} defaultValue={defaultValue}
                        onChange={onChange}
                        placeholder={placeholder || `Please enter item ${label}`}
                    />
                </Form.Item>)
                break;
            case 'number':
                element = (<Form.Item key={name} label={label}>
                    <InputNumber name={name} defaultValue={defaultValue}
                        onChange={onChange}
                        placeholder={placeholder || `Please enter item ${label}`}
                    />
                </Form.Item>)
                break;
            case 'decimal':
                element = (<Form.Item  key={name} label={label}>
                    <InputNumber name={name} defaultValue={defaultValue}
                        onChange={onChange}
                        placeholder={`${placeholder || ""}`}
                        step={0.1}
                    />
                </Form.Item>)
                break;
            case 'radio':
                element = (<Form.Item key={name} label={label}>
                    <Switch name={name} defaultChecked={defaultValue === '1' ? true : false}
                        onChange={onChange} />
                </Form.Item>)
                break;
            default:
                element = (<Form.Item  key={name}label={label}>
                    <Input name={name} defaultValue={defaultValue}
                        placeholder={placeholder} onChange={onChange} />
                </Form.Item>)
    
        }

        setFormElement(element);
    }

    const cleanup = ()=>{
        
    }
    
    useEffect(() => {
        getFormElem()
        return () => {
            cleanup();
        }
    }, [type]);

    return (<>{formElement}</>);
}

FieldGenerator.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func
}

export default FieldGenerator;
