import React, { useState, useContext, useEffect } from 'react';
import { Drawer, Form, Button, Col, Row, Input, Select, Divider, Icon, Upload, message, Switch, Steps, Alert, Avatar, Empty } from 'antd';
import GlobalStateContext from '../../../../context'
import Webservice from '../../../../classes/WebService';
import TextArea from 'antd/lib/input/TextArea';
import StreamWebService from '../../../../classes/StreamWebService';
import DestinationItem from './DestinationItem';

const { Option } = Select;
const { Step } = Steps;


const AddDestination = function (props) {
  const [state,dispatch] = useContext(GlobalStateContext);
  const {features_accessible} = state;
  const {  stream } = props;
  const { getFieldDecorator } = props.form;
  const [destinations, setDestinations] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [destination, setDestination] = useState(false);
  const [showDestinationForm, setShowDestinationForm] = useState(false);

  const [itemDestinations, setItemDestinations] = useState([ ...stream.destinations]);

  const onNewPlatformClick = () => {
    setShowDestinationForm(true)
  }
  const onDestFormClose = () => {
    setShowDestinationForm(false)
  }


  const renderDestinationItems = () => {
    return (
      <ul className="row m-l-5">
        {itemDestinations.map(dest => <DestinationItem stream={stream} item={dest} />)}
        <li>  <Button  hidden={showDestinationForm} onClick={onNewPlatformClick}> <Icon type="plus" /> </Button></li>
      </ul>
    )
  }


  const fetchDestinations = async () => {
    try {
      const webservice = new Webservice();
      webservice.get({ endpoint: "streams/destinations" })
        .then(res => res.data)
        .then(dest => setDestinations(dest));
    } catch (error) {
      message.error("Failed to fetch destinations");
    }
  }

  const cleanUp = () => {
    onDestFormClose();
    props.form.resetFields()
  }

  useEffect(() => {
    fetchDestinations();
    return cleanUp;
  }, []);



  const onSubmit = (e) => {
    e.preventDefault();
    let service;
    props.form.validateFields(async (err, values) => {
      if (!err) {
        setLoading(true);
        service = new StreamWebService();
        await service
          .addDestination({ id: stream.id, data: values })
          .then(resp => {
            setItemDestinations(resp.data.destinations)
            props.form.resetFields();
            onDestFormClose();
            message.success("Destination added");
          })
          .catch(err => {
            message.error(`Could not add destinaiton. ${err.message}`);
          });

        setLoading(false);

      }
    })
  }

  const destinationChanged = (val) => {
    let selected;
    for (let i = 0; i < destinations.length; i++) {
      if (destinations[i].id === val) {
        selected = destinations[i];
      }
    }
    setDestination(selected);
  }

  return (
    <div>
      <div className="card m-t-10">
        <div className="card-body">
          <h6>Platforms</h6>
          {!!itemDestinations ?

            renderDestinationItems()
            :
            <Empty description="No Platform">

            </Empty>
          }

          <div hidden={!showDestinationForm} className="platforms">
            <Form   >
              <div >
                <Form.Item label="Destination">
                  {getFieldDecorator('destination[type]', {
                    rules: [{ required: true, message: 'Please select destination' }],
                    validateTrigger: 'onBlur'
                  })(
                    <Select onChange={destinationChanged} placeholder="Please select destination">
                      {destinations.map((destination) =>
                        <Option key={destination.id} value={destination.id} >
                          <Avatar src={destination.logo} alt={destination.id} /> {destination.name}
                        </Option>)}

                    </Select>,
                  )}
                </Form.Item>
              </div>
              <div hidden={destination.base_url} >
                <Form.Item label={(destination.name ? destination.name : " Destination") + " Server"} >

                  {getFieldDecorator('destination[url]', {
                        initialValue:destination.base_url,
                    rules: [{ required: true, message: 'Please enter sever address' }],
                    validateTrigger: 'onBlur',
                
                  
                  })(<Input placeholder={destination.base_url} />)}
                </Form.Item>
              </div>
              <div >
                <Form.Item label={(destination.name ? destination.name : " Destination ") + " Key/Token"} >
                  {getFieldDecorator('destination[token]', {
                    rules: [{ required: true, message: 'Please enter key' }],
                    validateTrigger: 'onBlur'
                  })(<Input required placeholder={" eg. E34RW3WE3RSDFSDDE "} />)}
                </Form.Item>
              </div>
            </Form>

            <div
              style={{
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'center',
              }}
            >
              <Button key="cancel_button" onClick={onDestFormClose} style={{ marginRight: 8 }}>
                Cancel
            </Button>

              <Button key="submit_button" disabled={isLoading} type="button" onClick={onSubmit} type="primary">
                {isLoading ? <Icon type="loading" /> : "Submit"}
              </Button>
            </div>
          </div>


        </div>
      </div>




    </div>
  )
}

export default Form.create()(AddDestination);