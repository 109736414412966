import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Divider, Tag, Drawer, Icon, Popconfirm, message } from "antd";
import GlobalStateContext from '../../../context';
import { SHOW_NEW_DATA_FORM, FETCH_ADMIN_USERS } from '../../../store/actions/types'
import AdminUsersService from '../../../classes/AdminUsersService';
import CreateAdminUser from './components/CreateAdminUser';
import ViewAdminUser from './components/ViewAdminUser';
import EditAdminUser from './components/EditAdminUser';
import CustomTable from '../../../components/CustomTable';

let onViewButtonClick;
let onEditClick;
let onDeleteConfirm;
let onDeleteCancel;
let activeUser;

const renderRole = (role) => {
    return role?  <Tag > {role.name}</Tag> : null
}
const columns = [
    {
        key: 'name',
        title: 'Name',
        dataIndex: 'name',
        render: text => text,
        ellipsis: true,
    },
    {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        render: renderRole,
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        render: text => text,
        ellipsis: true,
    },
    {
        title: 'Last Login',
        dataIndex: 'last_login',
        key: 'last_login',
        render: text => text,
        ellipsis: true,
    },
    {
        title: 'Actions',
        // fixed: 'right',
        render: (user) => (<div>
            <Button type="link" onClick={onViewButtonClick.bind(this,user)}>View</Button>
            <Divider type="vertical" />
            <Button type="link" onClick={onEditClick.bind(this,user)}>Edit</Button>
            <Divider type="vertical" />
            <Popconfirm disabled={user.id == activeUser.id}
             title={`Are you sure delete ${user.name}?`}
             onConfirm={onDeleteConfirm.bind(this,user)}
             onCancel={onDeleteCancel}
             okText="Yes"
             cancelText="No"
            >

            <Button disabled={user.id == activeUser.id} type="link">Delete</Button>
            </Popconfirm>
        </div>)
    }
]

 function UserIndexPage(props) {
    const [state, dispatch] = useContext(GlobalStateContext);
    const [isLoading,setIsLoading] = useState(false)
    const [viewDetail,setViewDetail] = useState(false);
    const [selectedUser,setSelectedUser] = useState();
    const [selectedUsers,setSelectedUsers] = useState([]);

    activeUser = state.user;
    const {users} = state;
 
    const onClickNewButton = () => {
        dispatch({
            type: SHOW_NEW_DATA_FORM,
            payload: true
        })
    }
    const closeViewDrawer = ()=>{
        setViewDetail(false);
    }
     onViewButtonClick = (user) => {
        setViewDetail(user);
    }
     onEditClick = (user) => {
        setSelectedUser(user);
    }

    onDeleteCancel = ()=>{

    }

    onDeleteConfirm = (user)=>{
        setIsLoading(true);
        const adminUserWebService = new AdminUsersService();
        adminUserWebService.deleteUser(user.id)
        .then(res=>{
            message.success(`${user.name} deleted successfully`);
            fetchData()
        })
        .catch(err=> {
            message.error(`Failed to delete ${user.name}`);
            console.log(err)
        }).finally(()=> setIsLoading(true))
    }

    


    const fetchData = async ()=>{
        setIsLoading(true);
        const service = new AdminUsersService();
        const result = await service.getUsers();
        if(result.status ==  200){
            dispatch({
                type: FETCH_ADMIN_USERS,
                payload: result.data.entries
            })
        }
        setIsLoading(false);
    }

    useEffect(()=>{
        fetchData();      
    },[])
    const buttonStyle = {
        position: "relative",
        float: "right",
        button: "50px",
        margin: "10px 10px"

    }

    return (
        <div>
            <div >
                <h5>
                    Admin Users
                    <Button style={buttonStyle} type='dashed' onClick={onClickNewButton}>
                         <Icon type="user-add"/> New 
                         </Button>
                </h5>
            </div>
            <div>
                {/* <Table rowKey={record => record.id} rowSelection={rowSelection}  columns={columns} dataSource={users} loading={isLoading} ></Table> */}
                <CustomTable 
                rowKey={record => record.id} 
                // rowSelection={rowSelection}  
                columns={columns} 
                selected = {selectedUsers}
                dataSource="/admins/" 
                loading={isLoading}
                stateSubjectKey='users'
                />
            
            </div>
            <CreateAdminUser refreshListPage={fetchData}/>
            { selectedUser ? 
            <EditAdminUser  refreshListPage={fetchData} user={selectedUser} setSelectedUser={setSelectedUser}/> : null}
            <Drawer visible={viewDetail ? true : false} onClose={closeViewDrawer} width={'40vw'} >
                <ViewAdminUser detail={viewDetail} setViewDetail={setViewDetail}/>
            </Drawer>

        </div>


    )
}

export default withRouter(UserIndexPage)
