import React, { useState, useContext, useEffect } from 'react';
import { Drawer, Form, Button, Col, Row, Input, Select, Divider, Icon, Upload, message, Switch } from 'antd';
import GlobalStateContext from '../../../../context'
import { SHOW_NEW_DATA_FORM, SAVE_USER } from '../../../../store/actions/types';
import Webservice from '../../../../classes/WebService';
import { webservice, adminUserWebService, sanitizePhoneNumber } from '../../../../utils';
import ClientWebService from '../../../../classes/ClientWebService';

const { Option } = Select;

const CreateClient = function (props) {

  const { onClose } = props;
  const { getFieldDecorator } = props.form;
  const [name, setName] = useState('');
  const [accountTypes, setAccountTypes] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [acType, setAcType] = useState(false);
  const [state, dispatch] = useContext(GlobalStateContext);
  
  
  const fetchAccountTypes = async ()=>{
     try {
       const webservice = new Webservice();
          webservice.get({endpoint:"users/account_types"})
          .then(res =>res.data)
          .then(accountTypes => setAccountTypes(accountTypes));
     } catch (error) {
       message.error("Failed to fetch accountTypes");
     }
  }
  const cleanUp = ()=>{
      onClose();
      props.form.resetFields()
  }

  useEffect(()=>{
    fetchAccountTypes();
    return cleanUp;
  },[])

  const onSubmit =  (e) => {
    e.preventDefault();
    let service;
    props.form.validateFields( async (err, values) => {
      if (!err) {
        values.phone = sanitizePhoneNumber(values.phone);
        values.status = values.status === true ? '1' : '0';

        setLoading(true);
        service = new ClientWebService();
         await service
         .save({data:values})
         .then(resp =>{

          props.refreshListPage();
          props.form.resetFields();
          onClose();
          message.success("User saved successfully");
         })
        .catch(err => {
          message.error(`Could not save. ${err.message} `);
        });

        setLoading(false);
       
      }
    })
  }


  const nameOnChange = (e) => {
    setName(e.target.value);
  }
  const accTypeChanged= (val)=>{
    setAcType(val)
  }

  return (
    <div>

        <Form layout="vertical" >
      
              <Form.Item label="AccountType">
                {getFieldDecorator('account_type', {
                  rules: [{ required: true, message: 'Please select a account type' }],
                  initialValue : accountTypes[0]
                })(
                  <Select onChange={accTypeChanged} placeholder="Please select account type">
                    {accountTypes.map((account_type) => <Option key={account_type.id} value={account_type}>{account_type.toUpperCase()}</Option>)}
                  </Select>,
                )}
              </Form.Item>
          <Row gutter={16}>
     

            <Col span={12}>
              <Form.Item label={ acType == 'business' ? "Business Name" : "Name"}>
                {getFieldDecorator('name', {
                  rules: [{ required: true, message: 'Please enter user\'s name' }],
                  // initialValue:"",
                })(<Input placeholder="Please enter user name" onChange={nameOnChange} />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Email">
                {getFieldDecorator('email', {
                  rules: [{ type:'email', required: true, message: 'Please enter a valid user email' }],
                })(<Input placeholder="drew@mail.com"  />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={ acType == 'business' ? "Business Phone" : "Phone"}>
                {getFieldDecorator('phone', {
                  rules: [{ required: true, message: 'Phone number is required' }],
                })(<Input placeholder="Please enter phone number" />)}
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Password">
                {getFieldDecorator('password', {
                  rules: [{  required: true, message: 'Please enter a valid user pasword' }],
                })(<Input type="password" placeholder="password" />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label=" Account Status">
                {getFieldDecorator('status', {
                  rules: [{ required: true, message: 'Please choose the status' }],
                  initialValue: true
                })(
                  <Switch
                    checkedChildren={<Icon type='check'/>}
                    uncheckedChildren={<Icon type='close'/>}
                    
                  defaultChecked />
                )}
              </Form.Item>
            </Col>

          </Row>     
        </Form>
        <div
          style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
          }}
        >
          <Button onClick={onClose} style={{ marginRight: 8 }}>
            Cancel
            </Button>
          <Button disabled={isLoading} type="button" onClick={onSubmit} type="primary">
        {isLoading ? <Icon type="loading" />  : "Submit"}
            </Button>
        </div>
    </div>
  )
}


export default Form.create()(CreateClient);