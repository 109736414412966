import React from 'react'
import Webservice from './WebService';



export default class AdminUsersService extends Webservice {

    async getUsers() {

        try {
            return await this.get({ endpoint: 'admins', data: {} })
        } catch (error) {
            throw error;
        }
    }

    /**
     * Saves Users info
     * @param {*} param0 
     */
    async save({data, userId}) {

        try {
            return userId ? 
            await this.put({ endpoint: `admins/${userId}`, data }) : 
            await this.post({ endpoint: `admins`, data })
        } catch (error) {
            throw error;
        }
    }
 

    async deleteUser(userId) {
        try {
            return  await this.delete({ endpoint: `admins/${userId}`}); 
        } catch (error) {
            throw error;
        }
    }

}