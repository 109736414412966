import React, { useState, useEffect, useContext } from 'react';
import { Drawer, Tag, Divider, Col, Row, Skeleton, Timeline, Empty } from 'antd';
import StreamWebService from '../../../../classes/StreamWebService';
import DestinationItem from './DestinationItem';
import env from '../../../../environments';
import GlobalStateContext from '../../../../context';
import WaitingForStream from './WaitingForStream';
import FlvPlayer from '../../../../components/FlvPlayer';
import AddDestination from './AddDestination';
export default function ViewStream(props) {

    const { streamingServer } = env.app.api;
    const { live } = streamingServer.apps

    const [state, dispatch] = useContext(GlobalStateContext)
    const { io } = state;
    const { detail, setViewDetail } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);
    const [isActive, setIsActive] = useState(detail.status === '1');
    const [showToken, setShowToken] = useState(false);
    const [ioPayload, setIOPayload] = useState({});
    const publishBaseUrl = `${streamingServer.rtmp}${live}`;
    const previewLink = `${streamingServer.websocket}${live}/${detail.token}.flv`;

    const colors = {
        'personal': 'blue',
        'business': 'green',
    }

    const cleanup = ()=>{
            return 
    }

    useEffect(() => {
        
        return cleanup
    }, [detail])


    return (
        <div>
            <h4>{detail.title}</h4>
            <Row>
                <Col>
                    <p> <strong>Title:</strong>  {detail.title}</p>
                    <p> <strong>Token:</strong>  {detail.token}</p>
                    <p> <strong>Type:</strong> <Tag color={colors[detail.type]}>{detail.type} </Tag> </p>
                    <p> <strong>Client:</strong>  {detail.user.name}</p>
                    <p> <strong>Status:</strong>  {detail.status}</p>
                </Col>
                <Divider />
                <p> <strong>Created At </strong> : {(new Date(detail.createdAt)).toDateString()}</p>
                <Divider />
            </Row>

            <div>
                
                 <AddDestination stream={detail}/>       
          
            </div>
            <div className>
                <h5>Preview</h5>
                <div className="card">
                    {
                        !isActive ?
                            <WaitingForStream text={errorMsg || null} />
                            :
                            <FlvPlayer
                                showControls={false}
                                className="card-img-top"
                                url={previewLink}
                                isLive={true}
                                hasAudio={true}
                                isMuted={false}

                            />
                    }

                </div>

            </div>
        </div>
    )
}
