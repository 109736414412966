import React from 'react'
import moment from 'moment'

export default function ViewCoupon(props) {
    const { item, setViewDetail } = props;


    return (
        <div>
            <h4>{item.name}</h4>
            <div>
                <p> <strong> Title: </strong>  {item.title}</p>
                <p> <strong> Assigned To: </strong>  {item.assigned_to && item.assigned_to.name}</p>
                <p> <strong> Created At: </strong>  {(new Date(item.createdAt)).toDateString()}</p>
                <p> <strong> Starts At: </strong>  {( moment(item.start_at).format("MMM Do YYYY"))}</p>
                <p> <strong> Ends At: </strong>  {( moment(item.ends_at).format("MMM Do YYYY"))}</p>
                <p><strong> Value </strong> <h2>{item.value}</h2></p>
                <p><strong> Code </strong> <h2>{item.code}</h2></p>
            </div>
        </div>
    )
}
