import React from 'react'
import { Tag, Divider } from 'antd'

export default function ViewRole(props) {
    const { item, setViewDetail } = props;


    return (
        <div>
            <h4>{item.name}</h4>
            <div>
                <p> <strong>Name: </strong>  {item.name}</p>
                <p> <strong>Ref: </strong>  {item.ref_id}</p>
                {item.permissions ?  <p> <strong>Permissions: </strong>{ item.permissions.map(perm =>  <Tag>{perm.name}</Tag>)} </p>
                : null}
                <Divider />
                <p> <strong>Created At </strong> : {(new Date(item.createdAt)).toDateString()}</p>
                <Divider />
            </div>
        </div>
    )
}
