
import Dashboard from "../pages/admin/Dashboard";
import React from 'react';
import ListData from "../pages/admin/data/ListData";
import Landing from "../pages/front/Landing";
import Login from "../pages/front/Login";
import UserIndexPage from "../pages/admin/users/UserIndexPage";
import Role from "../pages/admin/settings/roles/Role";
import ConfigurationPage from "../pages/admin/settings/configuration/ConfigurationPage";
import ClientIndexPage from "../pages/admin/clients/ClientIndexPage";
import PackageIndexPage from "../pages/admin/packages/PackageIndexPage";
import StreamIndexPage from "../pages/admin/streams/StreamIndexPage";
import TransactionIndexPage from "../pages/admin/transactions/TransactionIndexPage";
import ConfigurationList from "../pages/admin/settings/configuration/components/ConfigurationList";
import CouponIndexPage from "../pages/admin/coupon/CouponIndexPage";
import MessengerIndexPage from "../pages/admin/messenger/MessengerIndexPage";

export const adminRoutes = [
    {
        name: "Dashboard",
        breadcrumbName: "Dashboard",
        component: <Dashboard/>,
        path: '/admin',
        icon: "dashboard",
        exact: true

    },
    {
        name: " Admin Users",
        breadcrumbName: " Admin Users",
        component: "",
        path: '/admin/admins',
        icon: "usergroup-add",
        children: [
            {
                name: "List",
                breadcrumbName: "List",
                component: <UserIndexPage/>,
                path: '/list',
                icon: "unordered-list"
            }
        ]

    },
    {
        name: "Clients",
        breadcrumbName: "Client",
        component: "",
        path: '/admin/clients',
        icon: "usergroup-add",
        children: [
            {
                name: "Client List",
                breadcrumbName: "List",
                component: <ClientIndexPage/>,
                path: '/list',
                icon: "unordered-list"
            }
        ]

    },
    {
        name: "Packages",
        breadcrumbName: "Packages",
        component: <PackageIndexPage/>,
        path: '/admin/packages',
        icon: "code-sandbox",

    },
    {
        name: " Streams",
        breadcrumbName: " Streams",
        component: <StreamIndexPage/> ,
        path: '/admin/stream',
        icon: "camera",
    },
    {
        name: " Transactions",
        breadcrumbName: " Payments",
        component: <TransactionIndexPage/> ,
        path: '/admin/payments',
        icon: "rise",
    },
    {
        name: " Coupon Code",
        breadcrumbName: " Coupon",
        component: <CouponIndexPage/> ,
        path: '/admin/coupon',
        icon: "container",
    },
    {
        name: " Messenger",
        breadcrumbName: " Messenger",
        component: "",
        path: '/admin/messenger',
        icon: "mail",
        children: [
            {
                name: "Index",
                breadcrumbName: "home",
                component: <MessengerIndexPage/>,
                path: '',
                icon: "mail"
            },
           
        ]

    },
    {
        name: " Setup",
        breadcrumbName: " Setup",
        component: "",
        path: '/admin/',
        icon: "setting",
        children: [
            {
                name: "Role",
                breadcrumbName: "Roles",
                component: <Role/>,
                path: 'roles/list',
                icon: "apartment"
            },
            {
                name: "App Settings",
                breadcrumbName: "Configuration",
                component: <ConfigurationPage/>,
                path: 'config',
                icon: "setting"
            },
            {
                name: "Config Vars",
                breadcrumbName: "Configuration Variables",
                component: <ConfigurationList/>,
                path: 'configvars',
                icon: "setting"
            }
        ]

    },
]
export const clientRoutes = [
    {
        path: '/',
        component: <Landing/>,
        name: "Welcome Page",
        icon: "dashboard",
        exact: true

    },
    {
        path: '/login',
        component: <Login/>,
        name: "Client Login",
        icon: "dashboard",
        exact: true

    },
]