import React, { useState, useContext, useEffect } from 'react';
import { Drawer, Form, Button, Col, Row, Input, Select, Divider, Icon, Upload, message, Switch } from 'antd';
import GlobalStateContext from '../../../../context'
import { SHOW_NEW_DATA_FORM, SAVE_USER } from '../../../../store/actions/types';
import Webservice from '../../../../classes/WebService';
import {  sanitizePhoneNumber } from '../../../../utils';
import ClientWebService from '../../../../classes/ClientWebService';



const { Option } = Select;

const EditClient = function (props) {
  const webservice = new Webservice()

  const { getFieldDecorator } = props.form;
  const { user, setSelectedUser } = props;

  const [name, setName] = useState('');
  const [accountTypes, setAccountTypes] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [state, dispatch] = useContext(GlobalStateContext);



  const fetchRoles = async () => {
    try {
      webservice.get({ endpoint: "users/account_types" })
        .then(res => res.data)
        .then(accountTypes => setAccountTypes(accountTypes));
    } catch (error) {
      message.error("Failed to fetch account types");
    }
  }

  useEffect(() => {
    fetchRoles();
  }, [])



  const onClose = () => {
    setSelectedUser(false);
  }

  const onSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        values.phone = sanitizePhoneNumber(values.phone);
        values.status = values.status === true ? '1' : '0';

        setLoading(true);
        const service = new ClientWebService();
        await service
          .save({ data: values, userId: user.id })
          .then(resp => {
            dispatch({
              type: SAVE_USER,
              payload: resp.data
            });
            setSelectedUser(false);
            props.refreshListPage();
            message.success("User info updated successfully");
          })
          .catch(err => {
            message.error(`Could not update user info. ${err.message} `);
          });

        setLoading(false);

      }
    })
  }


  const nameOnChange = (e) => {
    setName(e.target.value);
  }

  return (
    <div>
 
        <Form layout="vertical" >
          <h5> General Information</h5>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Name">
                {getFieldDecorator('name', {
                  rules: [{ required: true, message: 'Please enter user\'s name' }],
                  initialValue: user.name
                })(<Input placeholder="Please enter user name" onChange={nameOnChange} />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Email">
                {getFieldDecorator('email', {
                  rules: [{ type: 'email', required: true, message: 'Please enter a valid user email' }],
                  initialValue: user.email
                })(<Input placeholder="drew@mail.com" onChange={nameOnChange} />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Account Type">
                {getFieldDecorator('account_type', {
                  rules: [{ required: true, message: 'Please select type' }],
                  initialValue: user.account_type
                })(
                  <Select placeholder="Please select type">
                    {accountTypes.map((accType) => <Option key={accType} value={accType}>{accType}</Option>)}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Phone">
                {getFieldDecorator('phone', {
                  rules: [{ required: true, message: 'Please enter a valid phone number' }],
                  initialValue: user.phone
                })(<Input type="tel" placeholder="+23312345678" />)}
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label=" Account Status">
                {getFieldDecorator('status', {
                  rules: [{ required: true, message: 'Please choose the status' }],
                  initialValue: user.status ==='1' ? true :false
                })(
                  <Switch 
                  checkedChildren={<Icon type="check"/>}
                  uncheckedChildren={<Icon type="close"/>}
                  defaultChecked={user.status === '1' ? true : false }
                  />
                )}
              </Form.Item>
            </Col>
          </Row>

          <h5> Change Password</h5>
          <Row>
            <Col span={12}>
              <Form.Item label="New Password">
                {getFieldDecorator('password', {
                  rules: [{  message: 'Please must be at least 6 characters' }],
                })(<Input type="password" placeholder="password" />)}
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div
          style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
          }}
        >
          <Button size='small' onClick={onClose} style={{ marginRight: 8 }}>
            Cancel
            </Button>
          <Button disabled={isLoading} type="button" onClick={onSubmit} type="primary">
            {isLoading ? <Icon type="loading" /> : "Update"}
          </Button>
        </div>
    </div>
  )
}


export default Form.create()(EditClient);