import React, { useState, useContext, useEffect } from 'react';
import { Drawer, Form, Button, Col, Row, Input, Select, Divider, Icon, Upload, message } from 'antd';
import GlobalStateContext from '../../../../context'
import { SHOW_NEW_DATA_FORM, SAVE_USER } from '../../../../store/actions/types';
import Webservice from '../../../../classes/WebService';
import AdminUsersService from '../../../../classes/AdminUsersService';




const { Option } = Select;

const CreateAdminUser = function (props) {

  const webservice = new Webservice();
  
  const { getFieldDecorator } = props.form;
  const [name, setName] = useState('');
  const [roles, setRoles] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [state, dispatch] = useContext(GlobalStateContext);
  
  
  const fetchRoles = async ()=>{
     try {
          webservice.get({endpoint:"roles/"})
          .then(res =>res.data.entries)
          .then(roles => setRoles(roles));
     } catch (error) {
       message.error("Failed to fetch roles");
     }
  }

  useEffect(()=>{
    fetchRoles();
  },[])



  const onClose = () => {
    dispatch({
      type: SHOW_NEW_DATA_FORM,
      payload: false
    });
  }

  const onSubmit =  (e) => {
    e.preventDefault();
    props.form.validateFields( async (err, values) => {
      if (!err) {
        
        setLoading(true);
        const adminUserWebService = new AdminUsersService()
         await adminUserWebService
         .save({data:values})
         .then(resp =>{
          dispatch({
            type: SAVE_USER,
            payload: resp.data
          });
          dispatch({
            type: SHOW_NEW_DATA_FORM,
            payload: false
          });
          props.refreshListPage();
          message.success("User saved successfully");
         })
        .catch(err => {
          message.error(`Could not save. ${err.message} `);
        });

        setLoading(false);
       
      }
    })
  }


  const nameOnChange = (e) => {
    setName(e.target.value);
  }

  return (
    <div>
      <Drawer
        title="Add User"
        width={720}
        onClose={onClose}
        visible={state.showNewDataForm}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form layout="vertical" >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Name">
                {getFieldDecorator('name', {
                  rules: [{ required: true, message: 'Please enter user\'s name' }],
                })(<Input placeholder="Please enter user name" onChange={nameOnChange} />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Email">
                {getFieldDecorator('email', {
                  rules: [{ type:'email', required: true, message: 'Please enter a valid user email' }],
                })(<Input placeholder="drew@mail.com" onChange={nameOnChange} />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Role">
                {getFieldDecorator('role', {
                  rules: [{ required: true, message: 'Please select a role' }],
                })(
                  <Select placeholder="Please select role">
                    {roles.map((role) => <Option key={role.id} value={role.id}>{role.name}</Option>)}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Password">
                {getFieldDecorator('password', {
                  rules: [{  required: true, message: 'Please enter a valid user pasword' }],
                })(<Input type="password" placeholder="password" onChange={nameOnChange} />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label=" Account Status">
                {getFieldDecorator('status', {
                  rules: [{ required: true, message: 'Please choose the status' }],
                })(
                  <Select placeholder="Please choose the status">
                    <Option  key="0" value="0">Inactive</Option>
                    <Option key="1"  value="1">Active</Option>
                  </Select>,
                )}
              </Form.Item>
            </Col>

          </Row>     
        </Form>
        <div
          style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
          }}
        >
          <Button onClick={onClose} style={{ marginRight: 8 }}>
            Cancel
            </Button>
          <Button disabled={isLoading} type="button" onClick={onSubmit} type="primary">
        {isLoading ? <Icon type="loading" />  : "Submit"}
            </Button>
        </div>
      </Drawer>
    </div>
  )
}


export default Form.create()(CreateAdminUser);