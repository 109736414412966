import React, { useState, useContext, useEffect } from 'react';
import { Drawer, Form, Button, Col, Row, Input, Select, Divider, Icon, Upload, message, Switch, InputNumber } from 'antd';
import GlobalStateContext from '../../../../context'
import { SHOW_NEW_DATA_FORM, SAVE_USER } from '../../../../store/actions/types';
import Webservice from '../../../../classes/WebService';
import { sanitizePhoneNumber,sanitizeLabel } from '../../../../utils';
import ClientWebService from '../../../../classes/ClientWebService';
import PackageWebService from '../../../../classes/PackageWebService';

const { Option } = Select;

const CreatePackage = function (props) {

  const { onClose } = props;
  const { getFieldDecorator } = props.form;
  const [name, setName] = useState('');
  const [accountTypes, setAccountTypes] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [acType, setAcType] = useState(false);
  const [state, dispatch] = useContext(GlobalStateContext);
  const [features, setFeaturesTypes] = useState({});


  const fetchAccountTypes = async () => {
    try {
      const webservice = new Webservice();
      webservice.get({ endpoint: "users/account_types" })
        .then(res => res.data)
        .then(accountTypes => setAccountTypes(accountTypes));
    } catch (error) {
      message.error("Failed to fetch account types");
    }
  }
  const fetchFeaturesTypes = async () => {
    try {
      const webservice = new PackageWebService();
      webservice.getFeatureTypes()
        .then(res => res.data)
        .then(features => setFeaturesTypes(features));
    } catch (error) {
      message.error("Failed to fetch features");
    }
  }

  useEffect(() => {
    fetchAccountTypes();
    fetchFeaturesTypes();
    return cleanUp;
  }, [])

  const cleanUp = () => {
    onClose();
    props.form.resetFields()
  }


  const onSubmit = (e) => {
    e.preventDefault();
    let service;
    props.form.validateFields(async (err, values) => {
      if (!err) {
        values.phone = sanitizePhoneNumber(values.phone);
        values.status = values.status === true ? '1' : '0';
        values.is_free = values.is_free === true ? '1' : '0';
        values.visibility = values.is_free === true ? '1' : '0';

        setLoading(true);
        service = new PackageWebService();
        await service
          .save({ data: values })
          .then(resp => {

            props.refreshListPage();
            props.form.resetFields();
            onClose();
            message.success("Package saved successfully");
          })
          .catch(err => {
            message.error(`Could not save. ${err.message} `);
          });

        setLoading(false);

      }
    })
  }


  const nameOnChange = (e) => {
    setName(e.target.value);
  }
  const accTypeChanged = (val) => {
    setAcType(val)
  }
  const featureSection = () => {

    return  <div className="col col-sm-12">
 
      <table className="table">
     <tr>
        <th>
    
           Feature
           
        </th>
        <th>Value</th>
        </tr>
          
        {Object.keys(features).map((ft, i) => {
         let input = <></>;
         if (features[ft].type === "Number") {
           input = <InputNumber min="-1" />
         }
   
         if (features[ft].type === "Boolean") {
           input = <Switch
             checkedChildren={<Icon type="check" />}
             uncheckedChildren={<Icon type="close" />}
           />
         }
   
         return (
           <tr className="">
             <td> { features[ft].label ? features[ft].label : sanitizeLabel(ft).toUpperCase()}</td>
             <td>
               <Form.Item>
                 {
                   getFieldDecorator(`features[${ft}]`, {
                     rules: [{ required: true, message: ` ${ft} is required ` }],
                   })(input)}
               </Form.Item>
             </td>
           </tr>)
   
       })}
   
   </table>
     
    </div>
 }
  return (
    <div>

      <Form layout="vertical" >
        <h5> General Information</h5>

        <div className="row">
          <div className="col col-md-6 col-lg-3 col-sm-12">
            <Form.Item label="Name">
              {getFieldDecorator('name', {
                rules: [{ required: true, message: 'Please enter package\'s name' }],
              })(<Input placeholder="Please enter package name" onChange={nameOnChange} />)}
            </Form.Item>
          </div>

          <div className="col col-md-6 col-lg-3 col-sm-12">

            <Form.Item label="Price($)">
              {getFieldDecorator('price', {
                rules: [{ required: true, message: 'Please enter package\'s price' }],
                initialValue: 1
              })(<InputNumber type="number" min={0.00} placeholder="Please enter package price" />)}
            </Form.Item>
          </div>
          <div className="col col-md-6 col-lg-3 col-sm-12">

            <Form.Item label="Target Currency Code">
              {getFieldDecorator('target_currency', {
                rules: [],
                initialValue: "USD"
              })(<Input type="" min={0.00} placeholder="GHS" />)}
            </Form.Item>
          </div>
          
          <div className="col col-md-6 col-lg-3 col-sm-12">
            <Form.Item label="Duration">
              {getFieldDecorator('duration', {
                rules: [{ required: true, message: 'Please enter package\'s duration' }],
              })(<InputNumber min={1} placeholder="Please enter package duration" />)}
            </Form.Item>
          </div>
          <div className="col col-md-6 col-lg-3 col-sm-12">
            <Form.Item label="Frequency">
              {getFieldDecorator('frequency', {
                rules: [{ required: true, message: 'Please select frequency' }],
              })(
                <Select placeholder="Please select frequency">
                  {state.package_frequencies.map((fq) => <Option key={fq} value={fq}>{fq}</Option>)}
                </Select>,
              )}
            </Form.Item>

          </div>
          <div className="col col-md-6 col-sm-12">
            <Form.Item label="Account Type">
              {getFieldDecorator('account_type', {
                rules: [{ required: true, message: 'Please select type' }],
              })(
                <Select placeholder="Please select type">
                  {accountTypes.map((accType) => <Option key={accType} value={accType}>{accType}</Option>)}
                </Select>,
              )}
            </Form.Item>
          </div>


          { featureSection()}

          <div className="col col-md-6 col-sm-12">
            <Form.Item label="Visibility">
              {getFieldDecorator('visibility', {
                rules: [{ required: true, message: 'Please indicate visibility' }],
                initialValue: true
              })(
                <Switch
                  checkedChildren={<Icon type="check" />}
                  uncheckedChildren={<Icon type="close" />}
                  defaultChecked={true}
                />
              )}
            </Form.Item>
          </div>
          <div className="col col-md-6 col-sm-12">
            <Form.Item label="Status">
              {getFieldDecorator('status', {
                rules: [{ required: true, message: 'Please choose the status' }],
                initialValue: true
              })(
                <Switch
                  checkedChildren={<Icon type="check" />}
                  uncheckedChildren={<Icon type="close" />}
                  defaultChecked={true}
                />
              )}
            </Form.Item>
          </div>
          {/* <div className="col col-md-6 col-sm-12">
              <Form.Item label="Is Free">
                {getFieldDecorator('is_free', {
                  initialValue: "0"
                })(
                  <Switch 
                  checkedChildren={<Icon type="check"/>}
                  uncheckedChildren={<Icon type="close"/>}
                  defaultChecked={false}
                  />
                )}
              </Form.Item>
            </div> */}
        </div>

      </Form>
      <div
        style={{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
        }}
      >
        <Button size='small' onClick={onClose} style={{ marginRight: 8 }}>
          Cancel
            </Button>
        <Button disabled={isLoading} type="button" onClick={onSubmit} type="primary">
          {isLoading ? <Icon type="loading" /> : "Save"}
        </Button>
      </div>
    </div>
  )
}


export default Form.create()(CreatePackage);