import Webservice from './WebService';



export default class CouponWebService extends Webservice {

    async getCoupons(payload = {}) {

        try {
            return await this.get({ endpoint: 'coupons', data: payload })
        } catch (error) {
            throw error;
        }
    }

    async getCouponTypes() {

        try {
            return await this.get({ endpoint: 'coupons/types', data: {} })
        } catch (error) {
            throw error;
        }
    }

    /**
     * Saves Coupons info
     * @param {*} param0 
     */
    async save({data, id}) {

        try {
            return id ? 
            await this.put({ endpoint: `coupons/${id}`, data }) : 
            await this.post({ endpoint: `coupons`, data })
        } catch (error) {
            throw error;
        }
    }
 

    async deleteCoupon(id) {
        try {
            return  await this.delete({ endpoint: `coupons/${id}`}); 
        } catch (error) {
            throw error;
        }
    }

}