import React from 'react'

export default function WaitingForStream(props) {
    const {text,style} = props;
    const defaultStyle = { width: '100%', height: '300px' };
    return (
        <div>
            <div className="card-img-top bg-black " style={!!style ? style : defaultStyle} >
                <p className="text-center align-center text-white  waiting"> {!!text ? text :  "Waiting for stream" } </p>
            </div>
        </div>
    )
}
