import React, { useContext, useState, useEffect } from 'react'
import GlobalStateContext from '../../context'
import { Row, Col, Button, Icon, Form, Input, Select, Alert, message, Avatar, } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { SAVE_ITEM } from '../../store/actions/types';
import AdminUsersService from '../../classes/AdminUsersService';
import RoleWebService from '../../classes/RoleWebService';

const { Option } = Select;



function UserProfile(props) {
    const adminUserWebService = new AdminUsersService();
    const roleWebService = new RoleWebService();
    const { getFieldDecorator } = props.form;
    const [state, dispatch] = useContext(GlobalStateContext);
    const { user } = state;
    const [toggle, setToggle] = useState(0);//toggles between view and edit page
    const formItemStyle = { marginBottom: '5px' };
    const [isLoading, setIsLoading] = useState();
    const [roles, setRoles] = useState([]);
    const [error, setError] = useState();

    const onUpdateClick = (e) => {
        e.preventDefault();
        return toggle ? handleSubmit() : setToggle(!toggle);
    }


    useEffect(() => {
        roleWebService.getRoles().then(res => setRoles(res.data.entries));
    }, [state])


    const handleSubmit = () => {

        props.form.validateFields(async (err, values) => {
            setError(0);
            setIsLoading(1);

            if (!err) {

                try {
                    const data = { ...values, role: values.role[0] }
                    const result = await adminUserWebService.save({ data: data, userId: user.id });
                    setIsLoading(0);
                    if (result.status !== 200) {
                        return setError(result.message);
                    }
                    console.log(result);

                    dispatch({
                        type: SAVE_ITEM,
                        payload: ['user', result.data],

                    });

                    message.info("Profile saved successfully");
                    setToggle(false);

                } catch (error) {
                    setError(error.message);
                }
                // setToggle(!toggle)

            }

            setIsLoading(0);


        });
    };

    return (
        <div>
            <Avatar size={120} icon='user' style={{ display: 'block', margin: '0px auto' }} />
            <div className="user-profile-container" style={{ display: toggle ? 'none' : 'block' }}>
                <div className="user-profile-sections">
                    <div className="user-profile-section">
                        <div className="content">{user.name}</div>
                        <div className="title">Name</div>
                    </div>
                    <div className="user-profile-section">
                        <div className="content">{user.role.name}</div>
                        <div className="title">Role</div>
                    </div>
                    <div className="user-profile-section">
                        <div className="content">{user.email}</div>
                        <div className="title">Email</div>
                    </div>
                </div>
            </div>

            <div className="user-profile-container" style={{ display: toggle ? 'block' : 'none' }}>

                <Form >
                    <div className="user-profile-sections">
                        <div className="user-profile-section">
                            <FormItem style={formItemStyle}>
                                {getFieldDecorator('name', {
                                    rules: [{ required: true, message: 'Please input your name!' }],
                                    initialValue: user.name
                                })(
                                    <Input placeholder="Names" />,
                                )}
                            </FormItem>
                            <div className="title">Name</div>
                        </div>

                        <div className="user-profile-section">
                            <FormItem style={formItemStyle}>
                                {getFieldDecorator('email', {
                                    rules: [{ required: true, message: 'Please input your email!' }],
                                    initialValue: user.email
                                })(
                                    <Input placeholder="Email" />,
                                )}
                            </FormItem>
                            <div className="title">Email</div>
                        </div>
                        <div className="user-profile-section">
                            <FormItem style={formItemStyle}>
                                {getFieldDecorator('role', {
                                    rules: [{ required: true, message: 'Please input your name!' }],
                                    initialValue: [user.role._id]
                                })(
                                    <Select disabled={true} placeholder="Please select a role">
                                        {roles ? roles.map(role => <Option value={role.id}> {role.name}</Option>) : null}
                                    </Select>,
                                )}
                            </FormItem>
                            <div className="title">Role</div>
                        </div>
                    </div>
                    <h6>Change Password</h6>
                    <div className="user-profile-section">
                        <FormItem style={formItemStyle}>
                            {getFieldDecorator('password', {
                                rules: [{ min: 6, message: 'Password must be as least 6 characters ' }],
                            })(
                                <Input type="password" placeholder="Password" />,
                            )}
                        </FormItem>
                        <div className="title">Password <small>(optional)</small></div>
                    </div>
                    {error ? <Alert message={error} type="error" showIcon /> : null}

                </Form>
            </div>
            <Row style={{ marginTop: "1.5em" }}>
                <Col>
                    <Button type="dashed" block={true} onClick={onUpdateClick} >
                        <Icon type="edit" />
                        {toggle ? 'Save' : 'Update Profile'} {isLoading ? <Icon type='loading' /> : null}</Button>
                </Col>
                <Col></Col>
            </Row>

        </div>
    )
}


export default Form.create()(UserProfile)