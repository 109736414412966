import React, { useContext } from 'react'
import GlobalStateContext from '../../context'
import { useHistory, useLocation, Route, Redirect } from 'react-router-dom'
import LoginForm from '../../components/LoginForm';


export default function Login() {
    const [state, dispatch] = useContext(GlobalStateContext)
    const history = useHistory()
    const location = useLocation()


    const { from } = location.state ? location.state : { from: { pathname: '/' } }


    const doLogin = async (e) => {
        e.preventDefault()
        await dispatch(
            {
                type: 'LOGIN',
                payload: {}
            }
        )
        history.replace(from)
    }
    
    const LoginButton = () => {
        return (
            <div>
                <button type="button" onClick={doLogin}> Login </button>
            </div>
        )
    }

    const renderRoute = ({ location }) => {
        return state.user ?
            (<Redirect to={{ ...from, state: { from: location } }} />)
            :
            
            <LoginForm />

    }

    return (
        <Route render={renderRoute}></Route>
    )
}

