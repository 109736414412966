import React, { useState, useContext, useEffect } from 'react';
import { Drawer, Form, Button, Col, Row, Input, Select, Divider, Icon, Upload, message, Switch } from 'antd';
import GlobalStateContext from '../../../../../context'
import { SHOW_NEW_DATA_FORM, SAVE_ITEM } from '../../../../../store/actions/types';
import Webservice from '../../../../../classes/WebService';
import RoleWebService from '../../../../../classes/RoleWebService';



const { Option } = Select;

const EditRole = function (props) {
  const webservice = new Webservice();
  const roleWebService = new RoleWebService();
  const { getFieldDecorator } = props.form;
  const { item,refreshListPage,onClose} = props;

  const [name, setName] = useState('');
  const [permissions, setPerms] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [state, dispatch] = useContext(GlobalStateContext);



  const fetch = async () => {
    try {
      webservice.get({ endpoint: "permissions/" })
        .then(res => res.data.entries)
        .then(permissions => setPerms(permissions));
    } catch (error) {
      message.error("Failed to fetch permissions");
    }
  }

  useEffect(() => {
    fetch();
  }, [])


  const onSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {

        setLoading(true);
        await roleWebService
          .save({ data: values, id: item.id })
          .then(resp => {
            onClose();
            refreshListPage();
            message.success("Role saved successfully");
          })
          .catch(err => {
            message.error(`Could not save. ${err.message} `);
          });

        setLoading(false);

      }
    })
  }


  const nameOnChange = (e) => {
    setName(e.target.value);
  }

  return (
    <div>

        <Form layout="vertical" >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Name">
                {getFieldDecorator('name', {
                  rules: [{ required: true, message: 'Please enter item\'s name' }],
                  initialValue: item.name
                })(<Input placeholder="Please enter item name" onChange={nameOnChange} />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Ref Id">
              <Input value={item.ref_id} disabled/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Permissions">
                {getFieldDecorator('permissions', {
                  rules: [{ required: true, message: 'Please select a permissions' }],
                  initialValue: item.permissions.map(perm=> perm._id)
                })(
                  <Select mode="multiple" placeholder="Please select permissions">
                    {permissions.map((permission) => <Option  key={permission.id} value={permission.id}>{permission.name}</Option>)}
                  </Select>,
                )}
              </Form.Item>
            </Col>

            
            <Col span={12}>
              <Form.Item label="Status">
                {getFieldDecorator('status', {
                  
                })(
                  <Switch defaultChecked={item.status == '1'} onChange={(val)=> console.log(val)}  />
                )}
              </Form.Item>
            </Col>

          </Row>
        </Form>
        <div
          style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
          }}
        >
          <Button  onClick={onClose} style={{ marginRight: 8 }}>
            Cancel
            </Button>
          <Button disabled={isLoading} type="button" onClick={onSubmit} type="primary">
            {isLoading ? <Icon type="loading" /> : "Submit"}
          </Button>
        </div>
    </div>
  )
}


export default Form.create()(EditRole);