import React from 'react'
import {Switch} from "react-router-dom";
import AdminLayout from '../../layouts/AdminLayout';
import { adminRoutes } from "../../routers/routes";
import { buildRoutesItems } from "../../utils";

export default function AdminIndex() {
    return (
      <AdminLayout>
          <Switch>
            {buildRoutesItems(adminRoutes)}
          </Switch>
      </AdminLayout>
    )
}
