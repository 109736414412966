
import Webservice from './WebService';



export default class StreamWebService extends Webservice {


/**
 * 
 * @param {object} conditions 
 */
    async getStreams(conditions={}) {

        try {
            return await this.get({ endpoint: 'streams', data: conditions })
        } catch (error) {
            throw error;
        }
    }

        /**
     * 
     */
    async getStreamsTypes() {

        try {
            return await this.get({ endpoint: 'streams/types', data: {} })
        } catch (error) {
            throw error;
        }
    }
    
    /**
     * 
     */
    async getDestinations() {

        try {
            return await this.get({ endpoint: "streams/destinations", data: {} })
        } catch (error) {
            throw error;
        }
    }


    /**
     * Saves Streams info
     * @param {*} param0 
     */
    async save({data, id}) {

        try {
            return id ? 
            await this.put({ endpoint: `streams/${id}`, data }) : 
            await this.post({ endpoint: `streams`, data })
        } catch (error) {
            throw error;
        }
    }
 

    async getStream(id) {
        try {
            return  await this.get({ endpoint: `streams/${id}`}); 
        } catch (error) {
            throw error;
        }
    }


    async deleteStream(id) {
        try {
            return  await this.delete({ endpoint: `streams/${id}`}); 
        } catch (error) {
            throw error;
        }
    }

    async addDestination({ data, id }) {
        try {
            return await this.post({ endpoint: `streams/${id}/destinations`,data });
        } catch (error) {
            throw error;
        }
    }


    async removeDestination(streamid,destination_id) {
        try {
            const data = {type:destination_id}
            return await this.put({ endpoint: `streams/${streamid}/destinations/`,data});
        } catch (error) {
            throw error;
        }
    }

}